import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import logo from 'assets/images/logo_large.png';
import facebookLogo from 'assets/images/facebook_logo.png';
import twitterLogo from 'assets/images/twitter_logo.png';
import instagramLogo from 'assets/images/instagram_logo.png';
import webLogo from 'assets/images/web_logo.png';

import {
  PageWrapper,
  ShareContainer,
  LeftContainer,
  ShareImage,
  RightContainer,
  HeaderContainer,
  BodyContainer,
  FooterContainer,
  LogoContainer,
  Logo,
  LogoText,
  LogoSubText,
  Title,
  Paragraph,
  StyledButton,
  HorizontalRowContainer,
  HorizontalRow,
  HorizontalRowText,
  ShareButtons,
  ImageButton,
} from './styles';

const propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const Share = ({ location, history }) => {
  const urlParams = new URLSearchParams(location.search);
  const photo = urlParams.get('photo');

  const redirectToSignIn = () => {
    history.push('/signin');
  };

  return (
    <PageWrapper>
      <ShareContainer>
        <LeftContainer>
          <ShareImage src={photo} />
        </LeftContainer>
        <RightContainer>
          <HeaderContainer>
            <LogoContainer>
              <Logo src={logo}></Logo>
              <LogoText>Snaptivity</LogoText>
              <LogoSubText>
                On a mission to capture & share the world&apos;s moments at live events
              </LogoSubText>
            </LogoContainer>
            <Title>
              You focus on the game. <br />
              We&apos;ll focus on you.
            </Title>
          </HeaderContainer>
          <BodyContainer>
            <Paragraph>
              The moments when you leap up & roar with the crowd are priceless. But you can&apos;t
              live them to the full with a phone in your hand.
              <br />
              <br />
              We have got you covered.
            </Paragraph>
            <StyledButton type="primary" onClick={redirectToSignIn} id="getmoments_button">
              Get Your Moments
            </StyledButton>
          </BodyContainer>
          <FooterContainer>
            <HorizontalRowContainer>
              <HorizontalRow />
              <HorizontalRowText>Follow Us</HorizontalRowText>
            </HorizontalRowContainer>
            <ShareButtons>
              <a href="https://facebook.com/snaptivity" id="facebook_link">
                <ImageButton src={facebookLogo} />
              </a>
              <a href="https://twitter.com/snaptivity" id="twitter_link">
                <ImageButton src={twitterLogo} />
              </a>
              <a href="https://instagram.com/snaptivity" id="instagram_link">
                <ImageButton src={instagramLogo} />
              </a>
              <a href="http://snapdash.snaptivityapp.com/" id="snapdash_link">
                <ImageButton src={webLogo} />
              </a>
            </ShareButtons>
          </FooterContainer>
        </RightContainer>
      </ShareContainer>
    </PageWrapper>
  );
};

Share.propTypes = propTypes;

export default withRouter(Share);
