import { put, takeLatest, all, fork } from 'redux-saga/effects';
import campaignActionTypes from './constants/campaignActionTypes';

function* setFiltersSuccessSaga(action) {
  yield put({
    type: campaignActionTypes.SET_CAMPAIGN_FILTER_FULFILLED,
    data: action.payload.data,
  });
}

export function* setFiltersSaga() {
  yield takeLatest(campaignActionTypes.SET_CAMPAIGN_FILTER, setFiltersSuccessSaga);
}

function* removeFiltersSuccessSaga() {
  yield put({
    type: campaignActionTypes.REMOVE_CAMPAIGN_FILTER_FULFILLED,
  });
}

export function* removeFiltersSaga() {
  yield takeLatest(campaignActionTypes.REMOVE_CAMPAIGN_FILTER, removeFiltersSuccessSaga);
}

export default function* rootSaga() {
  yield all([fork(setFiltersSaga), fork(removeFiltersSaga)]);
}
