import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
  },
  header: {
    display: 'flex',
    marginLeft: 5,
  },
  eventTitle: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 20,
    marginTop: 5,
    textAlign: 'center',
  },
  imageContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    marginBottom: 16,
    width: '100%',
    marginLeft: '15%',
  },
  summaryTextContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    marginBottom: 16,
    width: '100%',
    marginLeft: '15%',
  },
  logo: {
    width: '50%',
  },
  imageText: {
    fontSize: 14,
    fontWeight: 100,
    width: '50%',
  },
  imageTextResult: {
    fontSize: 14,
    color: 'grey',
    width: '50%',
  },
  documentContainer: {
    padding: 10,
  },
  image: {
    height: 100,
    width: 100,
  },
});

export function PdfDocument(props) {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.documentContainer}>
          <View style={styles.header}>
            <Text style={styles.eventTitle}>{props.data.event_id.toUpperCase()}</Text>
          </View>

          <View style={styles.imageContainer}>
            <View style={styles.logo}>
              <Image style={styles.image} source={props.data.team_home_logo} />
            </View>
            <View style={styles.logo}>
              <Image style={styles.image} source={props.data.team_away_logo} />
            </View>
          </View>

          <View style={styles.summaryTextContainer}>
            <Text style={styles.imageText}>TEAMS PLAYED:</Text>
            <Text style={styles.imageTextResult}>
              {props.data.team_home} vs {props.data.team_away}
            </Text>
          </View>
          <View style={styles.summaryTextContainer}>
            <Text style={styles.imageText}>DATE:</Text>
            <Text style={styles.imageTextResult}>{props.data.event_day}</Text>
          </View>
          {props.data.status === 'Completed' && (
            <View style={styles.summaryTextContainer}>
              <Text style={styles.imageText}>RESULTS:</Text>
              <Text style={styles.imageTextResult}>{props.data.result}</Text>
            </View>
          )}
          <View style={styles.summaryTextContainer}>
            <Text style={styles.imageText}>VENUE:</Text>
            <Text style={styles.imageTextResult}>{props.data.venue_name}</Text>
          </View>
          {props.data.status === 'Completed' && (
            <View style={styles.summaryTextContainer}>
              <Text style={styles.imageText}>NUMBER OF REGISTRATION:</Text>
              <Text style={styles.imageTextResult}>{props.data.number_of_registrations}</Text>
            </View>
          )}
          <View style={styles.summaryTextContainer}>
            <Text style={styles.imageText}>STATUS:</Text>
            <Text style={styles.imageTextResult}>{props.data.status}</Text>
          </View>
          {props.data.status === 'Completed' && (
            <View style={styles.summaryTextContainer}>
              <Text style={styles.imageText}>NUMBER OF PHOTOS:</Text>
              <Text style={styles.imageTextResult}>{props.data.number_of_photos}</Text>
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
}
