const photoActionTypes = {
  GET_ALL_PHOTOS: 'GET_ALL_PHOTOS',
  GET_ALL_PHOTOS_REQUEST: 'GET_ALL_PHOTOS_REQUEST',
  GET_ALL_PHOTOS_SUCCESSFUL: 'GET_ALL_PHOTOS_SUCCESSFUL',
  GET_ALL_PHOTOS_FAILED: 'GET_ALL_PHOTOS_FAILED',
  GET_FAVORITE_PHOTOS: 'GET_FAVORITE_PHOTOS',
  GET_FAVORITE_PHOTOS_REQUEST: 'GET_FAVORITE_PHOTOS_REQUEST',
  GET_FAVORITE_PHOTOS_SUCCESSFUL: 'GET_FAVORITE_PHOTOS_SUCCESSFUL',
  GET_FAVORITE_PHOTOS_FAILED: 'GET_FAVORITE_PHOTOS_FAILED',
  CREATE_FAVORITE_PHOTO: 'CREATE_FAVORITE_PHOTO',
  CREATE_FAVORITE_PHOTO_REQUEST: 'CREATE_FAVORITE_PHOTO_REQUEST',
  CREATE_FAVORITE_PHOTO_SUCCESSFUL: 'CREATE_FAVORITE_PHOTO_SUCCESSFUL',
  CREATE_FAVORITE_PHOTO_FAILED: 'CREATE_FAVORITE_PHOTO_FAILED',
  REMOVE_FAVORITE_PHOTO: 'REMOVE_FAVORITE_PHOTO',
  REMOVE_FAVORITE_PHOTO_REQUEST: 'REMOVE_FAVORITE_PHOTO_REQUEST',
  REMOVE_FAVORITE_PHOTO_SUCCESSFUL: 'REMOVE_FAVORITE_PHOTO_SUCCESSFUL',
  REMOVE_FAVORITE_PHOTO_FAILED: 'REMOVE_FAVORITE_PHOTO_FAILED',
  REMOVE_TOP_PICKS_FAVORITE_PHOTO: 'REMOVE_TOP_PICKS_FAVORITE_PHOTO',
  REMOVE_TOP_PICKS_FAVORITE_PHOTO_REQUEST: 'REMOVE_TOP_PICKS_FAVORITE_PHOTO_REQUEST',
  REMOVE_TOP_PICKS_FAVORITE_PHOTO_SUCCESSFUL: 'REMOVE_TOP_PICKS_FAVORITE_PHOTO_SUCCESSFUL',
  REMOVE_TOP_PICKS_FAVORITE_PHOTO_FAILED: 'REMOVE_TOP_PICKS_FAVORITE_PHOTO_FAILED',
  FLAG_PHOTO: 'FLAG_PHOTO',
  FLAG_PHOTO_REQUEST: 'FLAG_PHOTO_REQUEST',
  FLAG_PHOTO_SUCCESSFUL: 'FLAG_PHOTO_SUCCESSFUL',
  FLAG_PHOTO_FAILED: 'FLAG_PHOTO_FAILED',
  SELECT_PHOTO: 'SELECT_PHOTO',
  SELECT_PHOTO_SUCCESSFUL: 'SELECT_PHOTO_SUCCESSFUL',
  UNSELECT_PHOTO: 'UNSELECT_PHOTO',
  UNSELECT_PHOTO_SUCCESSFUL: 'UNSELECT_PHOTO_SUCCESSFUL',
  SELECT_ALL_PHOTOS: 'SELECT_ALL_PHOTOS',
  SELECT_ALL_PHOTOS_SUCCESSFUL: 'SELECT_ALL_PHOTOS_SUCCESSFUL',
  UNSELECT_ALL_PHOTOS: 'UNSELECT_ALL_PHOTOS',
  UNSELECT_ALL_PHOTOS_SUCCESSFUL: 'UNSELECT_ALL_PHOTOS_SUCCESSFUL',
  GET_SEAT_MAP: 'GET_SEAT_MAP',
  GET_SEAT_MAP_REQUEST: 'GET_SEAT_MAP_REQUEST',
  GET_SEAT_MAP_SUCCESSFUL: 'GET_SEAT_MAP_SUCCESSFUL',
  GET_SEAT_MAP_FAILED: 'GET_SEAT_MAP_FAILED',
  GET_USER_FAVOURITE_ASSETS_FROM_VENUE: ' GET_USER_FAVOURITE_ASSETS_FROM_VENUE_REQUEST',
  GET_USER_FAVOURITE_ASSETS_FROM_VENUE_REQUEST: 'GET_USER_FAVOURITE_ASSETS_FROM_VENUE_REQUEST',
  GET_USER_FAVOURITE_ASSETS_FROM_VENUE_SUCCESS: 'GET_USER_FAVOURITE_ASSETS_FROM_VENUE_SUCCESS',
  GET_USER_FAVOURITE_ASSETS_FROM_VENUE_FAILED: 'GET_USER_FAVOURITE_ASSETS_FROM_VENUE_FAILED',

  POST_MY_ASSET: 'POST_MY_ASSET',
  POST_MY_ASSET_PENDING: 'POST_MY_ASSET_PENDING',
  POST_MY_ASSET_FULFILLED: 'POST_MY_ASSET_FULFILLED',
  POST_MY_ASSET_REJECTED: 'POST_MY_ASSET_REJECTED',

  GET_ASSETS_SUMMARY: 'GET_ASSETS_SUMMARY',
  GET_ASSETS_SUMMARY_REQUEST: 'GET_ASSETS_SUMMARY_REQUEST',
  GET_ASSETS_SUMMARY_SUCCESS: 'GET_ASSETS_SUMMARY_SUCCESS',
  GET_ASSETS_SUMMARY_FAILED: 'GET_ASSETS_SUMMARY_FAILED',

  GET_FAV_ASSETS_SUMMARY: 'GET_FAV_ASSETS_SUMMARY',
  GET_FAV_SUMMARY_REQUEST: 'GET_FAV_SUMMARY_REQUEST',
  GET_FAV_SUMMARY_SUCCESS: 'GET_FAV_SUMMARY_SUCCESS',
  GET_FAV_SUMMARY_FAILED: 'GET_FAV_SUMMARY_FAILED',

  GET_OVERLAY: 'GET_OVERLAY',
  GET_OVERLAY_REQUEST: 'GET_OVERLAY_REQUEST',
  GET_OVERLAY_SUCCESS: 'GET_OVERLAY_SUCCESS',
  GET_OVERLAY_FAILED: 'GET_OVERLAY_FAILED',
};

export default photoActionTypes;
