import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import AccountDetails from './AccountDetails/index';
import Users from './Users/index';

const MyAccount = ({match}) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/accountdetails`} />
    <Route path={`${match.url}/accountdetails`} component={AccountDetails} />
    <Route path={`${match.url}/users`} component={Users} />
  </Switch>
);

export default MyAccount;
