import photosActionTypes from './constants/photosActionTypes';

export const getAllPhotos = payload => ({
  type: photosActionTypes.GET_ALL_PHOTOS,
  payload,
});

export const getFavoritePhotos = payload => ({
  type: photosActionTypes.GET_FAVORITE_PHOTOS,
  payload,
});

export const createFavoritePhotos = payload => ({
  type: photosActionTypes.CREATE_FAVORITE_PHOTO,
  payload,
});

export const removeFavoritePhotos = payload => ({
  type: photosActionTypes.REMOVE_FAVORITE_PHOTO,
  payload,
});

export const flagPhoto = payload => ({
  type: photosActionTypes.FLAG_PHOTO,
  payload,
});

export const selectPhoto = payload => ({
  type: photosActionTypes.SELECT_PHOTO,
  payload,
});

export const unSelectPhoto = payload => ({
  type: photosActionTypes.UNSELECT_PHOTO,
  payload,
});

export const selectAllPhotos = payload => ({
  type: photosActionTypes.SELECT_ALL_PHOTOS,
  payload,
});

export const unSelectAllPhotos = () => ({
  type: photosActionTypes.UNSELECT_ALL_PHOTOS,
});

export const removeTopPicksFavoritePhotos = payload => ({
  type: photosActionTypes.REMOVE_TOP_PICKS_FAVORITE_PHOTO,
  payload,
});

export const getSeatMap = payload => ({
  type: photosActionTypes.GET_SEAT_MAP,
  payload,
});

export const getUserFavouriteAssestsFromVenue = payload => ({
  type: photosActionTypes.GET_USER_FAVOURITE_ASSETS_FROM_VENUE,
  payload,
});

export const postMyAsset = payload => ({
  type: photosActionTypes.POST_MY_ASSET,
  payload,
});

export const getOverlay = payload => ({
  type: photosActionTypes.GET_OVERLAY,
  payload,
});


export default {
  getAllPhotos,
  getFavoritePhotos,
  createFavoritePhotos,
  flagPhoto,
  unSelectPhoto,
  selectAllPhotos,
  unSelectAllPhotos,
  removeTopPicksFavoritePhotos,
  getUserFavouriteAssestsFromVenue,
};
