/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { Button, Form, Input, notification, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { StadiumLogoContainer } from './styles';

import stadiumImage from 'assets/images/stadium.jpg';
import Spinner from 'components/spinner/Spinner';

const FormItem = Form.Item;

const propTypes = {
  form: PropTypes.object.isRequired,
  signinData: PropTypes.object.isRequired,
  signin: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

class SignIn extends React.Component {
  handleSubmit = e => {
    const { form, signin, history } = this.props;
    const onSuccess = () => {
      history.push('mycontent/contentmanagement');
    };
    const onError = error => {
      notification.error({
        message: error,
      });
    };

    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        signin({
          email: values.email,
          password: values.password,
          onSuccess,
          onError,
        });
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      signinData,
    } = this.props;
    if (signinData.isPending) {
      return <Spinner />;
    }
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <StadiumLogoContainer>
                  <img src={stadiumImage} alt="Neature" />
                </StadiumLogoContainer>
              </div>
              <div className="gx-app-logo-wid">
                <h1>Sign In</h1>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require('assets/images/logo.png')} />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail!',
                      },
                    ],
                  })(<Input placeholder="Email" />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ],
                  })(<Input.Password placeholder="Password" />)}
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit" id="signin_button">
                    Sign In
                  </Button>
                  <span>or</span> <Link to="/signup">Sign Up</Link>
                </FormItem>
                <div className="gx-flex-row gx-justify-content-between">
                  <span>or connect with</span>
                  <ul className="gx-social-link">
                    <li>
                      <Icon
                        type="google"
                        onClick={() => Auth.federatedSignIn({ provider: 'Google' })}
                        id="signin_google_icon"
                      />
                    </li>
                    <li>
                      <Icon
                        type="facebook"
                        onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })}
                        id="signin_facebook_icon"
                      />
                    </li>
                  </ul>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SignIn.propTypes = propTypes;

const WrappedNormalLoginForm = Form.create()(SignIn);

export default WrappedNormalLoginForm;
