import { connect } from 'react-redux';
import ManageCampaign from './ManageCampaign';
import {
  setCampaignFilter,
  removeCampaignFilter,
} from '../../../features/campaign/campaignActions';

const mapDispatchToProps = dispatch => ({
  setCampaignFilter: payload => dispatch(setCampaignFilter(payload)),
  removeCampaignFilter: () => dispatch(removeCampaignFilter()),
});

export default connect(null, mapDispatchToProps)(ManageCampaign);
