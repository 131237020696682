import React, { Component, createRef } from 'react';
import { Form, Select, Row, Col, DatePicker, Button } from 'antd';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Link } from 'react-router-dom';
import approvedLogo from 'assets/images/campaign-approved-Icon.png';
import completedLogo from 'assets/images/campaign-completed_Icon.png';
import pendingLogo from 'assets/images/campaign-pending-Icon.png';
import { PdfDocument } from './PDFDocument';

const { Option } = Select;
const { RangePicker } = DatePicker;

const campaignData = [
  {
    event_id: 'arena1',
    team_home: 'Ajax',
    team_home_logo: 'https://jca-fans.snaptivityapp.com/logos/ajax.png',
    team_away: 'AZ Alkmaar',
    team_away_logo: 'https://jca-fans.snaptivityapp.com/logos/az.png',
    event_day: '2020-03-01',
    bkg_img: 'https://jca-fans.snaptivityapp.com/shared-file/images/event_AjaxAlkmaar_new',
    event_seats: 'https://jca-fans.snaptivityapp.com/shared-file/jca_seatmap.json',
    status: 'Completed',
    number_of_photos: 110,
    venue_name: 'Wembley Stadium',
    result: '2 - 2',
    number_of_registrations: 50,
  },
  {
    event_id: 'jca3',
    team_home: 'Ajax',
    team_home_logo: 'https://jca-fans.snaptivityapp.com/logos/ajax.png',
    team_away: 'AZ Alkmaar',
    team_away_logo: 'https://jca-fans.snaptivityapp.com/logos/az.png',
    event_day: '2018-03-01',
    bkg_img: 'https://jca-fans.snaptivityapp.com/shared-file/images/event_AjaxAlkmaar',
    event_seats: 'https://jca-fans.snaptivityapp.com/shared-file/jca_seatmap.json',
    status: 'Pending',
    number_of_photos: 79,
    venue_name: 'Wembley Stadium',
  },
  {
    event_id: 'jca2',
    team_home: 'Ajax',
    team_home_logo: 'https://jca-fans.snaptivityapp.com/logos/ajax.png',
    team_away: 'RKC Waalwijk',
    team_away_logo: 'https://jca-fans.snaptivityapp.com/logos/rkc.png',
    event_day: '2020-02-16',
    bkg_img: 'https://jca-fans.snaptivityapp.com/shared-file/images/event_AjaxWaalwijk',
    event_seats: 'https://jca-fans.snaptivityapp.com/shared-file/jca_seatmap.json',
    status: 'Approved',
    number_of_photos: 50,
    venue_name: 'Amsterdam Arena',
  },
  {
    event_id: 'jca1',
    team_home: 'Ajax',
    team_home_logo: 'https://jca-fans.snaptivityapp.com/logos/ajax.png',
    team_away: 'Den Haag',
    team_away_logo: 'https://jca-fans.snaptivityapp.com/logos/den_haag.png',
    event_day: '2019-12-22',
    bkg_img: 'https://jca-fans.snaptivityapp.com/shared-file/images/event_AjaxHaag',
    event_seats: 'https://jca-fans.snaptivityapp.com/shared-file/jca_seatmap.json',
    status: 'Completed',
    number_of_photos: 100,
    venue_name: 'Amsterdam Arena',
    result: '2 - 0',
    number_of_registrations: 85,
  },
]
  .slice()
  .sort((a, b) => new Date(b.event_day) - new Date(a.event_day));

class ManageCampaign extends Component {
  constructor(props) {
    super(props);

    this.selectRef = createRef();
    this.blobData = null;
    this.blobUrl = '';
  }
  state = {
    filteredcampaignData: [],
    status: [],
    dateArray: [],
  };
  componentDidMount() {
    this.props.removeCampaignFilter();

    this.setState({ filteredcampaignData: campaignData });
  }

  onViewDetailsClick = eventID => {
    this.props.setCampaignFilter({
      data: {
        eventID: [`${eventID}~`],
      },
    });

    this.props.history.push('/mycontent/contentmanagement');
  };

  onStatusChanged = value => {
    this.selectRef.current.blur();

    if (value.length && !this.state.dateArray.length) {
      // Handle for When date and status are filtered
      const filteredData = campaignData.filter(item => {
        return value.includes(item.status);
      });

      this.setState({ filteredcampaignData: filteredData, status: value });
    } else if (value.length && this.state.dateArray.length) {
      const getData = campaignData.filter(item => {
        const eventIdDate = new Date(item.event_day);
        const fromDate = new Date(this.state.dateArray[0]);
        const toDate = new Date(this.state.dateArray[1]);

        return (
          eventIdDate.getTime() >= fromDate.getTime() && eventIdDate.getTime() <= toDate.getTime()
        );
      });

      const filteredData = getData.filter(item => {
        return value.includes(item.status);
      });

      this.setState({ filteredcampaignData: filteredData, status: value });
    } else {
      if (!this.state.dateArray.length) {
        this.setState({
          filteredcampaignData: campaignData,
          status: [],
        });
      } else {
        const filterDataByDate = campaignData.filter(item => {
          const eventIdDate = new Date(item.event_day);
          const fromDate = new Date(this.state.dateArray[0]);
          const toDate = new Date(this.state.dateArray[1]);

          return (
            eventIdDate.getTime() >= fromDate.getTime() && eventIdDate.getTime() <= toDate.getTime()
          );
        });

        this.setState({
          filteredcampaignData: filterDataByDate,
          status: [],
        });
      }
    }
  };

  dateRangeOnChange = (_, dateString) => {
    if (dateString[0] && dateString[1] && this.state.status.length) {
      const getCampaignData = campaignData.filter(item => {
        const eventIdDate = new Date(item.event_day);
        const fromDate = new Date(dateString[0]);
        const toDate = new Date(dateString[1]);

        return (
          eventIdDate.getTime() >= fromDate.getTime() && eventIdDate.getTime() <= toDate.getTime()
        );
      });

      const filteredData = getCampaignData.filter(item => {
        return this.state.status.includes(item.status);
      });

      this.setState({ filteredcampaignData: filteredData, dateArray: dateString });
    } else if (dateString[0] && dateString[1]) {
      const filterDataByDate = campaignData.filter(item => {
        const eventIdDate = new Date(item.event_day);
        const fromDate = new Date(dateString[0]);
        const toDate = new Date(dateString[1]);

        return (
          eventIdDate.getTime() >= fromDate.getTime() && eventIdDate.getTime() <= toDate.getTime()
        );
      });

      this.setState({
        filteredcampaignData: filterDataByDate,
        dateArray: dateString,
      });
    } else {
      if (!this.state.status.length) {
        this.setState({
          filteredcampaignData: campaignData,
          dateArray: [],
        });
      } else {
        const filteredData = campaignData.filter(item => {
          return this.state.status.includes(item.status);
        });

        this.setState({ filteredcampaignData: filteredData, dateArray: [] });
      }
    }
  };

  componentWillUnmount() {
    if (this.blobUrl) this.blobData = null;
    if (this.blobUrl) this.blobUrl = URL.revokeObjectURL(this.blobUrl);
  }

  render() {
    return (
      <div className="campaignContainer">
        <Form className="manageCampaignForm">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <Form.Item className="manageCampaignFormItem">
                <Select
                  mode="multiple"
                  placeholder="Please select status"
                  getPopupContainer={trigger => trigger.parentNode}
                  onChange={this.onStatusChanged}
                  ref={this.selectRef}
                  id="statusfilter_dropdown"
                >
                  <Option key="Completed">Completed</Option>
                  <Option key="Approved">Approved</Option>
                  <Option key="Pending">Pending</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              <RangePicker
                format="YYYY-MM-DD"
                placeholder={['Start', 'End']}
                onChange={this.dateRangeOnChange}
                onOk={this.dateRangeOkClick}
                className="customRange"
                id="daterange_picker"
              />
            </Col>
          </Row>
          {this.state.filteredcampaignData.map((data, index) => {
            let imageURLStatus;
            if (data.status === 'Completed') imageURLStatus = completedLogo;
            else if (data.status === 'Pending') imageURLStatus = pendingLogo;
            else if (data.status === 'Approved') imageURLStatus = approvedLogo;

            let mobileScreenTeamPlayed;
            let viewDetailsMargin;
            let midScreen;
            let showNumberOfPhotos;
            let imageMarginBottom;
            let notCompleteSmallScreenStyle;
            switch (true) {
              case window.innerWidth <= 575:
                mobileScreenTeamPlayed = { marginLeft: '28%', marginTop: '20%' };
                viewDetailsMargin = { marginTop: '8%' };
                showNumberOfPhotos = true;
                break;
              case window.innerWidth > 575 && window.innerWidth <= 767:
                mobileScreenTeamPlayed = { marginLeft: '54%', marginTop: '20%' };
                break;
              case window.innerWidth <= 767:
                notCompleteSmallScreenStyle = { marginBottom: '4%' };
                break;
              case window.innerWidth >= 768 && window.innerWidth < 992:
                midScreen = { marginTop: '5%' };
                showNumberOfPhotos = true;
                imageMarginBottom = { marginTop: '2%' };
                break;
              case window.innerWidth >= 992 && window.innerWidth < 1200:
                notCompleteSmallScreenStyle = { marginBottom: '3%' };
                break;
              case window.innerWidth >= 1200 && data.status !== 'Completed':
                notCompleteSmallScreenStyle = { marginBottom: '1%' };
                break;
              default:
                mobileScreenTeamPlayed = {};
                viewDetailsMargin = {};
                midScreen = {};
                showNumberOfPhotos = false;
                imageMarginBottom = {};
                notCompleteSmallScreenStyle = { marginBottom: 0 };
            }

            const isDisabledButton = data.status === 'Completed' ? false : true;
            const isDisabledDownlloadLink =
              data.status === 'Completed' ? { pointerEvents: 'none', opacity: '0.4' } : {};
            const isDisabledEditLink =
              data.status === 'Pending' ? { pointerEvents: 'none', opacity: '0.4' } : {};

            return (
              <Row
                type="flex"
                justify="space-between"
                style={{ marginTop: 20 }}
                key={data.event_id}
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <div className="campaignContentCard">
                    <Row
                      type="flex"
                      justify="space-between"
                      className="manageCampaignContainerRow"
                      style={{ padding: 16, paddingBottom: 0 }}
                    >
                      <Col
                        xs={8}
                        sm={8}
                        md={8}
                        lg={6}
                        xl={2}
                        xxl={2}
                        style={{ ...notCompleteSmallScreenStyle, ...imageMarginBottom }}
                      >
                        <img alt="" src={imageURLStatus} />
                      </Col>
                      <Col xs={16} sm={16} md={7} lg={6} xl={5} xxl={5} style={midScreen}>
                        <div style={mobileScreenTeamPlayed}>
                          <p> Teams Played</p>
                          <p className="h6Headers">
                            {data.team_home} vs {data.team_away}
                          </p>
                        </div>
                      </Col>

                      <Col xs={12} sm={8} md={5} lg={6} xl={4} xxl={4} style={midScreen}>
                        <div>
                          <p>Date</p>
                          <p className="h6Headers">{data.event_day}</p>
                        </div>

                        {data.status === 'Completed' && (
                          <div>
                            <p>Results</p>
                            <p className="h6Headers">{data.result}</p>
                          </div>
                        )}
                      </Col>

                      <Col xs={12} sm={8} md={7} lg={6} xl={3} xxl={4}>
                        <div>
                          <p>Venue</p>
                          <p className="h6Headers">{data.venue_name}</p>
                        </div>

                        {data.status === 'Completed' && (
                          <div>
                            <p>Number of Registration</p>
                            <p className="h6Headers">{data.number_of_registrations}</p>
                          </div>
                        )}
                      </Col>

                      <Col xs={12} sm={8} md={7} lg={6} xl={3} xxl={4}>
                        <div>
                          <p>Status</p>
                          <p className="h6Headers">{data.status}</p>
                        </div>

                        {data.status === 'Completed' && showNumberOfPhotos && (
                          <div>
                            <p>Number of Photos</p>
                            <p className="h6Headers">{data.number_of_photos}</p>
                          </div>
                        )}
                      </Col>

                      {showNumberOfPhotos && (
                        <Col xs={12} sm={8} md={5} lg={6} xl={4} xxl={4}>
                          {data.status === 'Completed' && (
                            <div>
                              <p>Number of Photos</p>
                              <p className="h6Headers">{data.number_of_photos}</p>
                            </div>
                          )}
                        </Col>
                      )}

                      <Col xs={6} sm={8} md={8} lg={6} xl={1} xxl={1}>
                        <div style={isDisabledEditLink}>
                          <>
                            <PDFDownloadLink
                              document={<PdfDocument data={data} />}
                              fileName="campaign.pdf"
                            >
                              {({ blob, url }) => {
                                this.blobData = blob;
                                this.blobUrl = url;
                                return (
                                  <Button
                                    type="link"
                                    icon="download"
                                    id={`downloadpdf_button_${index}`}
                                    style={{ fontSize: '22px', marginRight: 10 }}
                                    size="default"
                                  />
                                );
                              }}
                            </PDFDownloadLink>
                          </>
                        </div>
                      </Col>

                      <Col
                        xs={6}
                        sm={8}
                        md={8}
                        lg={6}
                        xl={1}
                        xxl={1}
                        style={{ ...notCompleteSmallScreenStyle, ...isDisabledDownlloadLink }}
                      >
                        <Link
                          id={`edit_link_${index}`}
                          to={`/mycampaigns/managecampaign/${data.event_id}`}
                        >
                          <Button
                            type="link"
                            icon="edit"
                            style={{ fontSize: '22px' }}
                            size="default"
                            id={`edit_button_${index}`}
                          />
                        </Link>
                      </Col>

                      <Col xs={12} sm={8} md={5} lg={6} xl={4} xxl={3}>
                        <Button
                          size={window.innerWidth <= 575 ? 'small' : 'large'}
                          style={{ background: 'whitesmoke', ...viewDetailsMargin }}
                          onClick={() => this.onViewDetailsClick(data.event_id)}
                          id={`viewdetails_button_${index}`}
                          disabled={isDisabledButton}
                        >
                          View Details
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            );
          })}
        </Form>
      </div>
    );
  }
}

export default ManageCampaign;
