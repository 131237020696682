export const MESSAGES = {
  FETCHING_PHOTOS_REQUESTED: 'FETCHING_PHOTOS_REQUESTED',
  FETCHING_PHOTOS_SUCCESSFUL: 'FETCHING_PHOTOS_SUCCESSFUL',
  FETCHING_PHOTOS_FAILED: 'FETCHING_PHOTOS_FAILED',
  SEAT_MAP_REQUESTED: 'SEAT_MAP_REQUESTED',
  SEAT_MAP_SUCCESSFUL: 'SEAT_MAP_SUCCESSFUL',
  SEAT_MAP_FAILED: 'SEAT_MAP_FAILED',
  REMOVING_FAVOURITE_PHOTOS_REQUESTED: 'REMOVING_FAVOURITE_PHOTOS_REQUESTED',
  REMOVING_FAVOURITE_PHOTOS_SUCCESSFUL: 'REMOVING_FAVOURITE_PHOTOS_SUCCESSFUL',
  REMOVING_FAVOURITE_PHOTOS_FAILED: 'REMOVING_FAVOURITE_PHOTOS_FAILED',
  FETCHING_VENUES_REQUESTED: 'FETCHING_VENUES_REQUESTED',
  FETCHING_VENUES_SUCCESSFUL: 'FETCHING_VENUES_SUCCESSFUL',
  FETCHING_VENUES_FAILED: 'FETCHING_VENUES_FAILED',
  FETCHING_SUMMARY_REQUESTED: 'FETCHING_SUMMARY_REQUESTED',
  FETCHING_SUMMARY_SUCCESSFUL: 'FETCHING_SUMMARY_SUCCESSFUL',
  FETCHING_SUMMARY_FAILED: 'FETCHING_SUMMARY_FAILED',
  FETCHING_OVERLAY_REQUESTED: 'FETCHING_OVERLAY_REQUESTED',
  FETCHING_OVERLAY_SUCCESSFUL: 'FETCHING_OVERLAY_SUCCESSFUL',
  FETCHING_OVERLAY_FAILED: 'FETCHING_OVERLAY_FAILED',
};

export const FLAG_CODES = {
  Nudity: 'n1~',
  Violence: 'v1~',
  Abusive: 'a1~',
  Block: 'b1~',
};

export const MOMENT_CODES = {
  'goal~': 'Goal',
  'redcard~': 'Red Card',
  'ft~': 'Full Time',
  'yellowcard~': 'Yellow Card',
  'pen~': 'Penalty',
  'other~': 'Other',
  'ht~': 'Half Time',
};

export const EVENT_CODES = {
  'jca1~': 'JCA1',
  'jca2~': 'JCA2',
};

export default {
  MESSAGES,
  FLAG_CODES,
};
