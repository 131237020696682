import React from 'react';
import PropTypes from 'prop-types';
import { Hub } from 'aws-amplify';

import Spinner from 'components/spinner/Spinner';

const propTypes = {
  getCurrentAuthenticatedUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

class Social extends React.Component {
  componentDidMount() {
    const { history, getCurrentAuthenticatedUser } = this.props;
    const onSuccess = () => {
      history.push('mycontent/contentmanagement');
    };
    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
          getCurrentAuthenticatedUser({
            onSuccess,
          });
          break;
        default:
          break;
      }
    });
  }

  render() {
    return <Spinner />;
  }
}

Social.propTypes = propTypes;
export default Social;
