/* eslint-disable no-undef */
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createSagaMiddleWare from 'redux-saga';
import createRootReducer from '../reducers';
import rootSaga from '../../sagas';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['authReducer', 'assets', 'campaignReducer'],
};

const createBrowserHistory = require('history').createBrowserHistory;

const sagaMiddleWare = createSagaMiddleWare();

var composeEnhancers =
  process.env.REACT_APP_ENVIRONMENT === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);

const middlewares = [sagaMiddleWare, thunk, routeMiddleware];

const configureStore = createStore(
  persistReducer(persistConfig, createRootReducer(history)),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history), // for dispatching history actions
      ...middlewares,
    ),
  ),
);

sagaMiddleWare.run(rootSaga);

export const persistor = persistStore(configureStore);

export default configureStore;
