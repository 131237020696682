/* eslint-disable react/prop-types */
import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation, useHistory, useRouteMatch } from 'react-router-dom';

import MainApp from '../containers/App/MainApp';
import SignIn from './signin';
import SignUp from './signup';
import Share from './share';
import Social from './social';

import { setInitUrl } from 'appRedux/actions/Auth';

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from '../constants/ThemeSetting';
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from 'appRedux/actions/Setting';

const propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
  authUser: PropTypes.object,
};

const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

RestrictedRoute.propTypes = propTypes;

const App = () => {
  const { token } = useSelector(({ authReducer }) => authReducer);
  const { navStyle, layoutType } = useSelector(({ settings }) => settings);
  const { initURL } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();

  const authUser = token;
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = '/css/style.css';
    link.className = 'gx-style';
    document.body.appendChild(link);
  });

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has('theme')) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has('nav-style')) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has('layout-type')) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [dispatch, initURL, layoutType, location.pathname, location.search, navStyle]);

  const setLayoutType = layoutType => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setNavStyle = navStyle => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      if (authUser === null) {
        history.push('/signin');
      } else {
        history.push('mycontent/contentmanagement');
      }
    }
  }, [authUser, location, history]);

  return (
    <>
      <Switch>
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/share" component={Share} />
        <Route exact path="/social" component={Social} />
        <RestrictedRoute
          path={`${match.url}`}
          authUser={authUser}
          location={location}
          component={MainApp}
        />
      </Switch>
    </>
  );
};

export default memo(App);
