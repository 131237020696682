import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';
import photosReducers from '../../features/photos/photosReducers';
import authReducer from '../../features/auth/authReducer';
import campaignReducer from '../../features/campaign/campaignReducer';

const appReducer = history =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    commonData: Common,
    authReducer,
    assets: photosReducers,
    campaignReducer,
  });

export default history => (state, action) => {
  if (action.type === 'SIGNOUT') {
    state = undefined;
  }

  return appReducer(history)(state, action);
};
