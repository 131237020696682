import { put, takeLatest, call } from 'redux-saga/effects';
import axios from 'axios';
import photosActionTypes from './constants/photosActionTypes';
import apiPaths from '../../shared/apiPaths';
import appConstants from '../../shared/base/appConstants';
// import authActionTypes from '../auth/authActionTypes';

// Change Naming
function* getPhotosRequestSaga(action) {
  const {
    venueID = '',
    eventID = '',
    seatID = '',
    momentID = '',
    start = '',
    end = '',
    filtersApplied = false,
    count = '',
    pageNumber = 1,
    updatePhotos,
    getSummary = false,
    token,
  } = action.payload.data;

  const eventIDVar = eventID ? `&event_id=${eventID}` : '';

  const seatIDVar = seatID ? `&seat_id=${seatID}` : '';
  const momentIDVar = momentID ? `&moment_id=${momentID}` : '';
  const startVar = start ? `&start=${start}` : '';
  const endVar = end ? `&end=${end}` : '';
  const countVar = count ? `&count=${count}` : '';
  const nextToken = pageNumber + 1;

  const apiConfig = {
    method: 'GET',
    url: `${apiPaths.ASSETS}/${venueID}/?page=${pageNumber}${eventIDVar}${seatIDVar}${momentIDVar}${startVar}${endVar}${countVar}`,
    headers: {
      version: appConstants.VERSION,
      'x-api-key': appConstants.API_KEY,
      access_token: token.access_token,
      refresh_token: token.refresh_token,
    },
  };

  yield put({ type: photosActionTypes.GET_ALL_PHOTOS_REQUEST });
  getSummary && (yield put({ type: photosActionTypes.GET_ASSETS_SUMMARY_REQUEST }));

  try {
    const response = yield call(axios, apiConfig);
    const data = yield response;
    const fetchMore = data.status === 200 ? true : false;
    // console.log(data.data, 'Abhuja');
    let summary;
    let photoData;
    if (!data.data) {
      summary = [];
      photoData = [];
    } else {
      summary = data.data.feed.card_types[0].summary;
      photoData = data.data.feed.card_types[0].photo_cards;
    }

    getSummary &&
      (yield put({
        type: photosActionTypes.GET_ASSETS_SUMMARY_SUCCESS,
        summary: summary,
      }));

    yield put({
      type: photosActionTypes.GET_ALL_PHOTOS_SUCCESSFUL,
      data: photoData,
      filtersApplied,
      fetchMore,
      nextToken: fetchMore ? nextToken : 1,
      updatePhotos,
    });
  } catch (e) {
    // console.log(e, 'errroyu');
    getSummary &&
      (yield put({
        type: photosActionTypes.GET_ASSETS_SUMMARY_FAILED,
        data: e.message,
        fetchMore: false,
      }));
    yield put({
      type: photosActionTypes.GET_ALL_PHOTOS_FAILED,
      data: e.message,
      fetchMore: false,
      filtersApplied,
      nextToken: null,
    });

    if (e) {
      // yield put({ type: authActionTypes.SIGNOUT });
    }
  }
}

function* getFavoritePhotosRequestSaga(action) {
  const {
    venueID = '',
    eventID = '',
    seatID = '',
    momentID = '',
    start = '',
    end = '',
    filtersApplied = false,
    count = '',
    pageNumber = 1,
    updatePhotos,
    getSummary = false,
    token,
    resetMoment,
  } = action.payload.data;

  const eventIDVar = eventID ? `&event_id=${eventID}` : '';

  const seatIDVar = seatID ? `&seat_id=${seatID}` : '';
  const momentIDVar = momentID ? `&moment_id=${momentID}` : '';
  const startVar = start ? `&start=${start}` : '';
  const endVar = end ? `&end=${end}` : '';
  const countVar = count ? `&count=${count}` : '';
  const nextToken = pageNumber + 1;

  const apiConfig = {
    method: 'GET',
    url: `${apiPaths.ASSETS}/user/favourite/${venueID}/?page=${pageNumber}${eventIDVar}${seatIDVar}${momentIDVar}${startVar}${endVar}${countVar}`,
    headers: {
      version: appConstants.VERSION,
      'x-api-key': appConstants.API_KEY,
      access_token: token.access_token,
      refresh_token: token.refresh_token,
    },
  };

  getSummary && (yield put({ type: photosActionTypes.GET_FAV_SUMMARY_REQUEST }));

  yield put({ type: photosActionTypes.GET_FAVORITE_PHOTOS_REQUEST });
  try {
    const response = yield call(axios, apiConfig);
    const data = yield response;

    const fetchMore = data.status === 200 ? true : false;

    // console.log(data.data, 'data');
    let summary;
    let photoData;
    if (!data.data) {
      summary = [];
      photoData = [];
    } else {
      summary = data.data.feed.card_types[0].summary;
      photoData = data.data.feed.card_types[0].photo_cards;
    }

    // Modify
    getSummary &&
      (yield put({
        type: photosActionTypes.GET_FAV_SUMMARY_SUCCESS,
        summary: summary,
      }));

    yield put({
      type: photosActionTypes.GET_FAVORITE_PHOTOS_SUCCESSFUL,
      data: photoData,
      filtersApplied,
      fetchMore,
      nextToken: fetchMore ? nextToken : 1,
      updatePhotos,
      resetMoment,
    });
  } catch (e) {
    // console.log(e, 'Malalaal');
    getSummary &&
      (yield put({
        type: photosActionTypes.GET_FAV_SUMMARY_FAILED,
        data: e.message,
        fetchMore: false,
      }));

    yield put({
      type: photosActionTypes.GET_FAVORITE_PHOTOS_FAILED,
      data: e.message,
      fetchMore: false,
      filtersApplied,
      nextToken: null,
    });
    if (e) {
      // yield put({ type: authActionTypes.SIGNOUT });
    }
  }
}

function* createFavoritePhotoRequestSaga(action) {
  const apiConfig = {
    method: 'POST',
    url: `${apiPaths.ASSETS}/user/favourite/create`,
    data: action.payload.data,
    headers: {
      version: appConstants.VERSION,
      'x-api-key': appConstants.API_KEY,
    },
  };

  yield put({ type: photosActionTypes.CREATE_FAVORITE_PHOTO_REQUEST });
  try {
    const response = yield call(axios, apiConfig);
    const data = yield response;

    yield put({ type: photosActionTypes.CREATE_FAVORITE_PHOTO_SUCCESSFUL, data });
  } catch (e) {
    yield put({ type: photosActionTypes.CREATE_FAVORITE_PHOTO_FAILED, data: e.message });
  }
}

function* removeFavoritePhotoRequestSaga(action) {
  const apiConfig = {
    method: 'POST',
    url: `${apiPaths.ASSETS}/user/favourite/remove`,
    data: action.payload.data,
    headers: {
      version: appConstants.VERSION,
      'x-api-key': appConstants.API_KEY,
    },
  };

  yield put({ type: photosActionTypes.REMOVE_FAVORITE_PHOTO_REQUEST });
  try {
    const response = yield call(axios, apiConfig);
    const data = yield response;

    yield put({ type: photosActionTypes.REMOVE_FAVORITE_PHOTO_SUCCESSFUL, data });
  } catch (e) {
    yield put({ type: photosActionTypes.REMOVE_FAVORITE_PHOTO_FAILED, data: e.message });
  }
}

function* removeTopPicksFavoritePhotoRequestSaga(action) {
  const apiConfig = {
    method: 'POST',
    url: `${apiPaths.ASSETS}/user/favourite/remove`,
    data: action.payload.data,
    headers: {
      version: appConstants.VERSION,
      'x-api-key': appConstants.API_KEY,
    },
  };

  yield put({ type: photosActionTypes.REMOVE_TOP_PICKS_FAVORITE_PHOTO_REQUEST });
  try {
    const response = yield call(axios, apiConfig);
    const data = yield response;

    yield put({
      type: photosActionTypes.REMOVE_TOP_PICKS_FAVORITE_PHOTO_SUCCESSFUL,
      assets: action.payload.data,
      data: {
        config: data.config.data,
      },
    });
  } catch (e) {
    yield put({
      type: photosActionTypes.REMOVE_TOP_PICKS_FAVORITE_PHOTO_FAILED,
      data: e.message,
      assets: action.payload.data,
    });
  }
}

function* flagPhotoRequestSaga(action) {
  const apiConfig = {
    method: 'POST',
    url: `${apiPaths.ASSETS}/flag/create`,
    data: action.payload,
    headers: {
      version: appConstants.VERSION,
      'x-api-key': appConstants.API_KEY,
    },
  };

  yield put({ type: photosActionTypes.FLAG_PHOTO_REQUEST });
  try {
    const response = yield call(axios, apiConfig);
    const data = yield response;

    yield put({ type: photosActionTypes.FLAG_PHOTO_SUCCESSFUL, data });
  } catch (e) {
    yield put({ type: photosActionTypes.FLAG_PHOTO_FAILED, data: e.message });
  }
}

function* getUserFavouriteAssestsFromVenueRequestSaga() {
  const apiConfig = {
    method: 'GET',
    url: `${apiPaths.ASSETS}/events/jca`,
    headers: {
      version: appConstants.VERSION,
      'x-api-key': appConstants.API_KEY,
    },
  };

  yield put({ type: photosActionTypes.GET_USER_FAVOURITE_ASSETS_FROM_VENUE_REQUEST });
  try {
    const response = yield call(axios, apiConfig);
    const data = yield response;

    yield put({ type: photosActionTypes.GET_USER_FAVOURITE_ASSETS_FROM_VENUE_SUCCESS, data });
  } catch (e) {
    yield put({
      type: photosActionTypes.GET_USER_FAVOURITE_ASSETS_FROM_VENUE_FAILED,
      data: e.message,
    });
  }
}

function* selectPhotoSuccessSaga(action) {
  const { payload = {} } = action;
  yield put({ type: photosActionTypes.SELECT_PHOTO_SUCCESSFUL, data: payload });
}

function* unSelectPhotoSuccessSaga(action) {
  const { payload = {} } = action;
  yield put({ type: photosActionTypes.UNSELECT_PHOTO_SUCCESSFUL, data: payload });
}

function* selectAllPhotosSuccessSaga(action) {
  const { payload = {} } = action;
  yield put({ type: photosActionTypes.SELECT_ALL_PHOTOS_SUCCESSFUL, data: payload });
}

function* unSelectAllPhotosSuccessSaga() {
  yield put({ type: photosActionTypes.UNSELECT_ALL_PHOTOS_SUCCESSFUL });
}

function* getSeatMapRequestSaga() {
  const apiConfig = {
    method: 'GET',
    url: `https://jca-fans.snaptivityapp.com/shared-file/jca_seatmap.json`,
  };

  yield put({ type: photosActionTypes.GET_SEAT_MAP_REQUEST });
  try {
    const response = yield call(axios, apiConfig);
    const data = yield response;

    yield put({
      type: photosActionTypes.GET_SEAT_MAP_SUCCESSFUL,
      data,
    });
  } catch (e) {
    yield put({
      type: photosActionTypes.GET_SEAT_MAP_FAILED,
      data: e.message,
    });
  }
}

function* postMyAssetRequestSaga(action) {
  const apiConfig = {
    method: 'POST',
    url: `${apiPaths.ASSETS}/publish`,
    data: action.payload.data,
    headers: {
      version: appConstants.VERSION,
      'x-api-key': appConstants.API_KEY,
    },
  };

  yield put({ type: photosActionTypes.POST_MY_ASSET_PENDING });
  try {
    const response = yield call(axios, apiConfig);
    yield put({ type: photosActionTypes.POST_MY_ASSET_FULFILLED, payload: response.data.data });
    action.payload.onSuccess && action.payload.onSuccess();
  } catch (e) {
    yield put({ type: photosActionTypes.POST_MY_ASSET_REJECTED, payload: e.message });
  }
}

function* getOverlayRequestSaga(action) {
  const { venueID, eventID } = action.payload.data;
  const eventIDVar = eventID ? `?event_id=${eventID}` : '';

  const apiConfig = {
    method: 'GET',
    url: `${apiPaths.ASSETS}/overlay/${venueID}/${eventIDVar}`,
    headers: {
      version: appConstants.VERSION,
      'x-api-key': appConstants.API_KEY,
    },
  };

  yield put({ type: photosActionTypes.GET_OVERLAY_REQUEST });
  try {
    const response = yield call(axios, apiConfig);
    const data = yield response;

    yield put({
      type: photosActionTypes.GET_OVERLAY_SUCCESS,
      data,
    });
  } catch (e) {
    yield put({
      type: photosActionTypes.GET_OVERLAY_FAILED,
      data: e.message,
    });
  }
}

// Change Naming
export function* getPhotosSaga() {
  yield takeLatest(photosActionTypes.GET_ALL_PHOTOS, getPhotosRequestSaga);
}

export function* getFavoritePhotosSaga() {
  yield takeLatest(photosActionTypes.GET_FAVORITE_PHOTOS, getFavoritePhotosRequestSaga);
}

export function* createFavoritePhotoSaga() {
  yield takeLatest(photosActionTypes.CREATE_FAVORITE_PHOTO, createFavoritePhotoRequestSaga);
}

export function* removeFavoritePhotoSaga() {
  yield takeLatest(photosActionTypes.REMOVE_FAVORITE_PHOTO, removeFavoritePhotoRequestSaga);
}

export function* removeTopPicksFavoritePhotoSaga() {
  yield takeLatest(
    photosActionTypes.REMOVE_TOP_PICKS_FAVORITE_PHOTO,
    removeTopPicksFavoritePhotoRequestSaga,
  );
}

export function* flagPhotoSaga() {
  yield takeLatest(photosActionTypes.FLAG_PHOTO, flagPhotoRequestSaga);
}

export function* selectPhotoSaga() {
  yield takeLatest(photosActionTypes.SELECT_PHOTO, selectPhotoSuccessSaga);
}

export function* unSelectPhotoSaga() {
  yield takeLatest(photosActionTypes.UNSELECT_PHOTO, unSelectPhotoSuccessSaga);
}

export function* selectAllPhotosSaga() {
  yield takeLatest(photosActionTypes.SELECT_ALL_PHOTOS, selectAllPhotosSuccessSaga);
}

export function* unSelectAllPhotosSaga() {
  yield takeLatest(photosActionTypes.UNSELECT_ALL_PHOTOS, unSelectAllPhotosSuccessSaga);
}

export function* getSeatMapSaga() {
  yield takeLatest(photosActionTypes.GET_SEAT_MAP, getSeatMapRequestSaga);
}

export function* getUserFavouriteAssestsFromVenueSaga() {
  yield takeLatest(
    photosActionTypes.GET_USER_FAVOURITE_ASSETS_FROM_VENUE,
    getUserFavouriteAssestsFromVenueRequestSaga,
  );
}

export function* postMyAssetSaga() {
  yield takeLatest(photosActionTypes.POST_MY_ASSET, postMyAssetRequestSaga);
}

export function* getOverlaySaga() {
  yield takeLatest(photosActionTypes.GET_OVERLAY, getOverlayRequestSaga);
}
