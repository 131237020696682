import React, { Component } from 'react';
import { Form, Row, Col } from 'antd';
import approvedLogo from 'assets/images/campaign-approved-Icon.png';
import pendingLogo from 'assets/images/campaign-pending-Icon.png';

const dummyData = [
  {
    event_id: 'arena1',
    team_home: 'Ajax',
    team_home_logo: 'https://jca-fans.snaptivityapp.com/logos/ajax.png',
    team_away: 'AZ Alkmaar',
    team_away_logo: 'https://jca-fans.snaptivityapp.com/logos/az.png',
    event_day: '2020-03-01',
    bkg_img: 'https://jca-fans.snaptivityapp.com/shared-file/images/event_AjaxAlkmaar_new',
    event_seats: 'https://jca-fans.snaptivityapp.com/shared-file/jca_seatmap.json',
    status: 'Completed',
    number_of_photos: 110,
    venue_name: 'Wembley Stadium',
    result: '2 - 2',
    number_of_registrations: 50,
  },
  {
    event_id: 'jca3',
    team_home: 'Ajax',
    team_home_logo: 'https://jca-fans.snaptivityapp.com/logos/ajax.png',
    team_away: 'AZ Alkmaar',
    team_away_logo: 'https://jca-fans.snaptivityapp.com/logos/az.png',
    event_day: '2018-03-01',
    bkg_img: 'https://jca-fans.snaptivityapp.com/shared-file/images/event_AjaxAlkmaar',
    event_seats: 'https://jca-fans.snaptivityapp.com/shared-file/jca_seatmap.json',
    status: 'Pending',
    number_of_photos: 79,
    venue_name: 'Wembley Stadium',
  },
  {
    event_id: 'jca2',
    team_home: 'Ajax',
    team_home_logo: 'https://jca-fans.snaptivityapp.com/logos/ajax.png',
    team_away: 'RKC Waalwijk',
    team_away_logo: 'https://jca-fans.snaptivityapp.com/logos/rkc.png',
    event_day: '2020-02-16',
    bkg_img: 'https://jca-fans.snaptivityapp.com/shared-file/images/event_AjaxWaalwijk',
    event_seats: 'https://jca-fans.snaptivityapp.com/shared-file/jca_seatmap.json',
    status: 'Approved',
    number_of_photos: 50,
    venue_name: 'Amsterdam Arena',
  },
  {
    event_id: 'jca1',
    team_home: 'Ajax',
    team_home_logo: 'https://jca-fans.snaptivityapp.com/logos/ajax.png',
    team_away: 'Den Haag',
    team_away_logo: 'https://jca-fans.snaptivityapp.com/logos/den_haag.png',
    event_day: '2019-12-22',
    bkg_img: 'https://jca-fans.snaptivityapp.com/shared-file/images/event_AjaxHaag',
    event_seats: 'https://jca-fans.snaptivityapp.com/shared-file/jca_seatmap.json',
    status: 'Completed',
    number_of_photos: 100,
    venue_name: 'Amsterdam Arena',
    result: '2 - 0',
    number_of_registrations: 85,
  },
];

class CampaignManagement extends Component {
  state = {
    data: [],
  };
  componentDidMount() {
    const getLastIndex = this.props.history.location.pathname
      .substring(this.props.history.location.pathname.lastIndexOf('/'))
      .slice(1);

    const filteredData = dummyData.filter(item => item.event_id === getLastIndex);

    this.setState({
      data: filteredData,
    });
  }

  render() {
    return (
      <div className="campaignContainer">
        <Form className="manageCampaignForm">
          {this.state.data.map(data => {
            let imageURLStatus;
            if (data.status === 'Pending') imageURLStatus = pendingLogo;
            else if (data.status === 'Approved') imageURLStatus = approvedLogo;

            let notCompleteSmallScreenStyle;
            if (data.status !== 'Completed') {
              notCompleteSmallScreenStyle = { marginBottom: '1%' };
            } else if (window.innerWidth <= 767) {
              notCompleteSmallScreenStyle = { marginBottom: '4%' };
            } else {
              notCompleteSmallScreenStyle = { marginBottom: 0 };
            }

            let mobileScreenTeamPlayed;
            switch (true) {
              case window.innerWidth <= 575:
                mobileScreenTeamPlayed = { marginLeft: '43%', marginTop: '4%' };
                break;
              case window.innerWidth > 575 && window.innerWidth <= 767:
                mobileScreenTeamPlayed = { marginLeft: '24%', marginTop: '4%' };
                break;
              default:
                mobileScreenTeamPlayed = {};
            }

            return (
              <Row type="flex" justify="start" style={{ marginTop: 20 }} key={data.event_id}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div className="campaignContentCard">
                    <Row
                      type="flex"
                      justify="start"
                      className="manageCampaignContainerRow"
                      style={{ padding: 16, paddingBottom: 0 }}
                    >
                      <Col xs={5} sm={4} md={2} lg={2} xl={2} style={notCompleteSmallScreenStyle}>
                        <img alt="" src={imageURLStatus} />
                      </Col>
                      <Col xs={18} sm={18} md={6} lg={6} xl={6}>
                        <div style={mobileScreenTeamPlayed}>
                          <h6 className="h6Headers"> Teams Played</h6>
                          <p>
                            {data.team_home} vs {data.team_away}
                          </p>
                        </div>
                      </Col>

                      <Col xs={12} sm={8} md={5} lg={5} xl={5}>
                        <div>
                          <h6 className="h6Headers">Date</h6>
                          <p>{data.event_day}</p>
                        </div>
                      </Col>

                      <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                        <div>
                          <h6 className="h6Headers">Venue</h6>
                          <p>{data.venue_name}</p>
                        </div>
                      </Col>

                      <Col xs={12} sm={8} md={5} lg={5} xl={5}>
                        <div>
                          <h6 className="h6Headers">Status</h6>
                          <p>{data.status}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            );
          })}
        </Form>
      </div>
    );
  }
}

export default CampaignManagement;
