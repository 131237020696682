import React, { useState } from 'react';
import CardBox from '../../../components/CardBox';
import {
  Tabs,
  Form,
  Select,
  Row,
  Col,
  Divider,
  DatePicker,
  Input,
  Button,
  Icon,
  Steps,
  Progress,
  Checkbox,
} from 'antd';
import SocialMediaSharingButtons from '../../../components/Buttons/SocialMediaSharingButtons';
import BasicModal from '../../../components/Modal/BasicModal';
import { useRef } from 'react';

const { TabPane } = Tabs;
const { Option } = Select;
const { Step } = Steps;

const steps = [
  {
    title: 'Campaign Sets',
  },
  {
    title: 'Ad Sets',
  },
  {
    title: 'Review',
  },
];

const { MonthPicker } = DatePicker;

const AddVenueModal = ({ openAddVenueModal, modalStyle, onCloseModal }) => {
  return (
    <BasicModal open={openAddVenueModal} modalStyle={modalStyle} onCloseModal={onCloseModal}>
      <div>
        <h3
          style={{
            paddingTop: '20px',
            fontWeight: 600,
            marginTop: 10,
            marginLeft: 16,
            marginBottom: 16,
          }}
        >
          Add Venue
        </h3>
        <Form>
          <div>
            <Row type="flex" justify="start">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: '0px' }}>
                  <p className="modalFormLabel">Venue Name</p>
                  <Form.Item className="modalFormItem">
                    <Input placeholder="Johan cruyff arena" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: '0px' }}>
                  <p className="modalFormLabel">Location</p>
                  <Form.Item className="modalFormItem">
                    <Input placeholder="Amsterdam" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: '0px' }}>
                  <p className="modalFormLabel">Capacity</p>
                  <Form.Item className="modalFormItem">
                    <Input placeholder="55 000" />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
          </div>
          <div className="modalButtonContainer">
            <Button className="editCancelModalButton" onClick={onCloseModal}>
              Cancel
            </Button>
            <Button className="editSaveModalButton">Add</Button>
          </div>
        </Form>
      </div>
    </BasicModal>
  );
};

const AddVenueModalStyle = {
  modal: {
    height: '410px',
    backgroundColor: '#f3f3f3',
    width: '500px',
    borderRadius: '10px',
  },
  closeButton: {
    margin: '30px',
    cursor: 'pointer',
  },
  closeIcon: {
    fill: '#C8C8C8',
    width: '22px',
  },
  transitionExit: {
    opacity: '1',
  },
};
const CreateCampaign = props => {
  const [current, setCurrent] = useState(0);
  const [openAddVenueModal, setopenAddVenueModal] = useState(false);
  const divRef = useRef(null);

  const next = () => {
    // var elmnt = document.getElementById('nana');
    // elmnt.scrollLeft = 5000;
    // elmnt.scrollTop = 5000;
    console.log(divRef.current, 'divRef');

    setCurrent(prevCurrent => prevCurrent + 1);
  };

  const prev = () => {
    setCurrent(prevCurrent => prevCurrent - 1);
  };

  const onAddVenueModalClose = () => {
    setopenAddVenueModal(false);
  };

  const openAddVenueModalClick = () => {
    setopenAddVenueModal(true);
  };

  // console.log(divRef, 'divRef');
  // if (divRef.current && current === 1) {
  //   divRef.current.scrollTop = 500;
  // }
  return (
    <>
      {current === 0 && (
        <>
          <div className="headerContainer" id="nana">
            <h1>Build your Campaign</h1>
            <h3>Specify the objective and setup</h3>
          </div>
          <Row type="flex" justify="start">
            <Col xs={16} sm={16} md={16} lg={16} xl={16}>
              <CardBox
                heading="Event Details"
                headerStyle={{ fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 'bold' }}
                style={{ width: '80%', marginLeft: 50 }}
              >
                <h5 style={{ color: 'grey' }}>Event Type</h5>

                <Tabs type="card" style={{ marginTop: '10px' }} className="customTab">
                  <TabPane tab="Sports" key="1">
                    <Form className="eventdetailsForm">
                      <div className="customTabContainer">
                        <Row type="flex" justify="start">
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            style={{ margin: '20px 20px 0px 20px' }}
                          >
                            <Form.Item className="eventDetailsFormItem">
                              <Select
                                showSearch
                                placeholder="Search Venue"
                                onChange={() => {}}
                                style={{}}
                              >
                                <Option key={1} onClick={openAddVenueModalClick}>
                                  Add W +
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row type="flex" justify="start" style={{ marginBottom: '16px' }}>
                          <Col
                            xs={10}
                            sm={10}
                            md={10}
                            lg={10}
                            xl={10}
                            style={{ marginLeft: '6px' }}
                          >
                            <h5 style={{ color: 'grey' }}>Event Date</h5>
                            <MonthPicker
                              onChange={() => {}}
                              placeholder="MM/YY"
                              size="large"
                              className="customInput"
                            />
                          </Col>
                          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                            <h5 style={{ color: 'grey' }}>Competition/League</h5>
                            <Input
                              size="large"
                              placeholder="Name"
                              style={{ width: '90%', background: 'whitesmoke' }}
                            />
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2} xl={2} style={{ marginTop: '30px' }}>
                            <i
                              className="icon icon-close-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle"
                              style={{ cursor: 'pointer' }}
                            />
                          </Col>
                        </Row>
                        <div className="addButtonContainer">
                          <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle" />
                        </div>
                      </div>
                    </Form>
                  </TabPane>
                  <TabPane tab="Music" key="2">
                    <Form className="eventdetailsForm">
                      <div className="customTabContainer">
                        <Row type="flex" justify="start">
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            style={{ margin: '20px 20px 0px 20px' }}
                          >
                            <Form.Item className="eventDetailsFormItem">
                              <Select
                                showSearch
                                placeholder="Search Venue"
                                onChange={() => {}}
                                style={{}}
                              >
                                <Option key={1}>Add W +</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <Row type="flex" justify="start" style={{ marginBottom: '16px' }}>
                          <Col
                            xs={10}
                            sm={10}
                            md={10}
                            lg={10}
                            xl={10}
                            style={{ marginLeft: '6px' }}
                          >
                            <h5 style={{ color: 'grey' }}>Event Date</h5>
                            <MonthPicker
                              onChange={() => {}}
                              placeholder="MM/YY"
                              size="large"
                              className="customInput"
                            />
                          </Col>
                          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                            <h5 style={{ color: 'grey' }}>Competition/League</h5>
                            <Input
                              size="large"
                              placeholder="Name"
                              style={{ width: '90%', background: 'whitesmoke' }}
                            />
                          </Col>
                          <Col xs={2} sm={2} md={2} lg={2} xl={2} style={{ marginTop: '30px' }}>
                            <i
                              className="icon icon-close-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle"
                              style={{ cursor: 'pointer' }}
                            />
                          </Col>
                        </Row>
                        <div className="addButtonContainer">
                          <i className="icon icon-add-circle gx-fs-lg gx-d-inline-flex gx-vertical-align-middle" />
                        </div>
                      </div>
                    </Form>
                  </TabPane>
                </Tabs>
              </CardBox>
              <CardBox
                heading="Select Your Objective"
                style={{ width: '80%', marginLeft: 50 }}
                headerStyle={{ fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 'bold' }}
              >
                <Row type="flex" justify="start" style={{ marginBottom: '16px' }}>
                  <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                    <h5 style={{ color: 'grey' }}>Awareness</h5>
                    <Button
                      size="large"
                      style={{ fontSize: '14px', background: 'whitesmoke', color: 'black' }}
                    >
                      Reach <Icon type="info-circle" style={{ color: 'grey' }} />
                    </Button>
                    <Button
                      size="large"
                      style={{ fontSize: '14px', background: 'whitesmoke', color: 'black' }}
                    >
                      Brand Awareness <Icon type="info-circle" style={{ color: 'grey' }} />
                    </Button>
                  </Col>
                  <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                    <h5 style={{ color: 'grey' }}>Consideration</h5>
                    <Button
                      size="large"
                      style={{ fontSize: '14px', background: 'whitesmoke', color: 'black' }}
                    >
                      App Installs <Icon type="info-circle" style={{ color: 'grey' }} />
                    </Button>
                    <Button
                      size="large"
                      style={{ fontSize: '14px', background: 'whitesmoke', color: 'black' }}
                    >
                      Drive Web Traffic <Icon type="info-circle" style={{ color: 'grey' }} />
                    </Button>
                    <Button
                      size="large"
                      style={{ fontSize: '14px', background: 'whitesmoke', color: 'black' }}
                    >
                      Drive Apps Traffic <Icon type="info-circle" style={{ color: 'grey' }} />
                    </Button>
                  </Col>
                  <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                    <h5 style={{ color: 'grey' }}>Conversion</h5>
                    <Button
                      size="large"
                      style={{ fontSize: '14px', background: 'whitesmoke', color: 'black' }}
                    >
                      Conversion <Icon type="info-circle" style={{ color: 'grey' }} />
                    </Button>
                  </Col>
                </Row>
              </CardBox>
              <CardBox
                heading="Campaign Setup"
                style={{ width: '80%', marginLeft: 50 }}
                headerStyle={{ fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 'bold' }}
              >
                <Row type="flex" justify="start" style={{ marginBottom: '16px' }}>
                  <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                    <h5 style={{ color: 'grey' }}>Campaign Name</h5>
                    <Input size="large" placeholder="Name" className="competitionInput" />
                  </Col>
                </Row>
                <Row type="flex" justify="start" style={{ marginBottom: '16px' }}>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingRight: '0px' }}>
                    <h5 style={{ color: 'grey' }}>
                      Start & End <Icon type="info-circle" style={{ color: 'grey' }} />
                    </h5>
                    <Input
                      size="large"
                      placeholder="5 Days Before Event"
                      className="competitionInput"
                      style={{ width: '95%' }}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ marginTop: '23px', paddingLeft: '0px' }}
                  >
                    <Input
                      size="large"
                      placeholder="10 Days After Event"
                      className="competitionInput"
                      style={{ width: '95%' }}
                    />
                  </Col>
                </Row>
                <h5 style={{ color: 'grey', marginLeft: '17px' }}>
                  Content Delivery Integration <Icon type="info-circle" style={{ color: 'grey' }} />
                </h5>
                <Row type="flex" justify="start" style={{ marginBottom: '16px' }}>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ paddingRight: '0px' }}>
                    <Button
                      size="large"
                      style={{ fontSize: '14px', background: 'whitesmoke', color: 'black' }}
                    >
                      Club App
                    </Button>
                  </Col>
                  <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ paddingRight: '0px' }}>
                    <Button
                      size="large"
                      style={{ fontSize: '14px', background: 'whitesmoke', color: 'black' }}
                    >
                      Club Website
                    </Button>
                  </Col>
                  <Col xs={8} sm={8} md={7} lg={7} xl={7} style={{ paddingRight: '0px' }}>
                    <Button
                      size="large"
                      style={{ fontSize: '14px', background: 'whitesmoke', color: 'black' }}
                    >
                      Standalone Website
                    </Button>
                  </Col>
                  <Col xs={6} sm={6} md={2} lg={2} xl={2} style={{ paddingRight: '0px' }}>
                    <Button
                      size="large"
                      style={{ fontSize: '14px', background: 'whitesmoke', color: 'black' }}
                    >
                      Chatbot
                    </Button>
                  </Col>
                </Row>
                <Row type="flex" justify="start" style={{ marginBottom: '16px' }}>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingRight: '0px' }}>
                    <h5 style={{ color: 'grey' }}>
                      Notification Settings <Icon type="info-circle" style={{ color: 'grey' }} />
                    </h5>
                    <Button
                      size="large"
                      style={{
                        fontSize: '14px',
                        background: 'whitesmoke',
                        color: 'black',
                        width: '95%',
                      }}
                    >
                      Photo Availability
                    </Button>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ marginTop: '23px', paddingLeft: '0px' }}
                  >
                    <Button
                      size="large"
                      style={{
                        fontSize: '14px',
                        background: 'whitesmoke',
                        color: 'black',
                        width: '95%',
                      }}
                    >
                      Time
                    </Button>
                  </Col>
                </Row>
                <Row type="flex" justify="start" style={{ marginBottom: '16px' }}>
                  <Col xs={24} sm={24} md={23} lg={24} xl={24} style={{ paddingRight: '0px' }}>
                    <SocialMediaSharingButtons containerClass="shareIcons" />
                  </Col>
                </Row>
              </CardBox>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <CardBox
                heading="Summary"
                headerStyle={{ fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 'bold' }}
                style={{ width: '70%' }}
                cardBottomBorder="summaryBottomBorder"
              >
                <Row type="flex" justify="start">
                  <Col
                    xs={18}
                    sm={18}
                    md={18}
                    lg={18}
                    xl={18}
                    style={{ padding: '0px' }}
                    className="summaryCol"
                  >
                    <div>
                      <p>
                        Event Type
                        <span> Sports </span>
                      </p>
                      <p>
                        Objective
                        <span> Awareness </span>
                      </p>
                      <p>
                        Campaign
                        <span> Snaptivity </span>
                      </p>
                      <p>
                        Status
                        <span> Active </span>
                      </p>
                      <p>
                        Start & End
                        <span></span>
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBox>
            </Col>
          </Row>
        </>
      )}

      {current === 1 && (
        <>
          <div className="headerContainer" ref={divRef} id='mona'>
            <h1>Build your Activation Sets</h1>
            <h3>Manage campaign goal, targeting and budget</h3>
          </div>
          <Row type="flex" justify="start">
            <Col xs={16} sm={16} md={16} lg={16} xl={16}>
              <CardBox
                heading="Activation Format"
                headerStyle={{ fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 'bold' }}
                style={{ width: '80%', marginLeft: 50 }}
              >
                <div>
                  <h5 style={{ color: 'grey' }}>Activation Set Name</h5>
                  <Input size="large" placeholder="Name" className="activationInput" />
                </div>

                <div style={{ marginTop: 20 }}>
                  <h5 style={{ color: 'grey' }}>Activation Format</h5>
                </div>
              </CardBox>
              <div>
                <CardBox
                  heading="Audience"
                  style={{ width: '80%', marginLeft: 50 }}
                  headerStyle={{ fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 'bold' }}
                >
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ paddingLeft: 2 }}>
                    <Form className="audienceCampaignForm">
                      <h5 style={{ color: 'grey' }}>Location</h5>
                      <Row type="flex" justify="start">
                        <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                          <Form.Item className="audienceFormItem">
                            <Select className="" placeholder="Include" onChange={() => {}} />
                          </Form.Item>
                        </Col>
                        <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                          <Form.Item className="audienceFormItem">
                            <Select className="" placeholder="Country" onChange={() => {}} />
                          </Form.Item>
                        </Col>
                        <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                          <Form.Item className="audienceFormItem">
                            <Select className="" placeholder="City" onChange={() => {}} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <h5 style={{ color: 'grey', marginTop: 12 }}>Age</h5>
                      <Row type="flex" justify="start">
                        <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                          <Form.Item className="audienceFormItem">
                            <Select className="" placeholder="18" onChange={() => {}} />
                          </Form.Item>
                        </Col>
                        <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                          <Form.Item className="audienceFormItem">
                            <Select className="" placeholder="40+" onChange={() => {}} />
                          </Form.Item>
                        </Col>
                      </Row>

                      <h5 style={{ color: 'grey', marginTop: 12 }}>Gender</h5>
                      <Row type="flex" justify="start">
                        <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ paddingLeft: 0 }}>
                          <Button
                            style={{
                              fontSize: '14px',
                              background: 'whitesmoke',
                              width: '90%',
                              height: 45,
                            }}
                          >
                            Male
                          </Button>
                        </Col>
                        <Col xs={7} sm={7} md={7} lg={7} xl={7} style={{ paddingLeft: 2 }}>
                          <Button
                            style={{
                              fontSize: '14px',
                              background: 'whitesmoke',
                              width: '90%',
                              height: 45,
                              paddingLeft: 2,
                            }}
                          >
                            Female
                          </Button>
                        </Col>
                        <Col
                          xs={7}
                          sm={7}
                          md={7}
                          lg={7}
                          xl={7}
                          style={{ paddingLeft: 2, paddingRight: 30 }}
                        >
                          <Button
                            style={{
                              fontSize: '14px',
                              background: 'whitesmoke',
                              width: '100%',
                              height: 45,
                            }}
                          >
                            Other
                          </Button>
                        </Col>
                      </Row>

                      <h5 style={{ color: 'grey', marginTop: 12 }}>Language</h5>
                      <Row type="flex" justify="start">
                        <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                          <Form.Item className="audienceFormItem">
                            <Select
                              className=""
                              placeholder="Select Language"
                              onChange={() => {}}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <h5 style={{ color: 'grey', marginTop: 12 }}>
                        Lifetime Budget <Icon type="info-circle" style={{ color: 'grey' }} />
                      </h5>
                      <Row type="flex" justify="start">
                        <Col xs={14} sm={14} md={14} lg={14} xl={14} style={{ padding: 0 }}>
                          <Input
                            size="large"
                            placeholder="Name"
                            style={{ width: '91%', background: 'whitesmoke' }}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </CardBox>
              </div>

              <div>
                <CardBox
                  heading="Placement"
                  style={{ width: '80%', marginLeft: 50 }}
                  headerStyle={{ fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 'bold' }}
                >
                  <Tabs type="card" style={{ marginTop: '10px' }} className="customTab">
                    <TabPane tab="Automatic Placement" key="1">
                      <Row type="flex" justify="start" style={{ marginBottom: 10 }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Checkbox onChange={() => {}} className="menuCheckBox flagPhotoCheckBox">
                            <span style={{ fontSize: 16, color: 'black' }}>Goal</span>
                          </Checkbox>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Checkbox onChange={() => {}} className="menuCheckBox flagPhotoCheckBox">
                            <span style={{ fontSize: 16, color: 'black' }}>Penalty</span>
                          </Checkbox>
                        </Col>
                      </Row>
                      <Row type="flex" justify="start" style={{ marginBottom: 10 }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Checkbox onChange={() => {}} className="menuCheckBox flagPhotoCheckBox">
                            <span style={{ fontSize: 16, color: 'black' }}>Half Time</span>
                          </Checkbox>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Checkbox onChange={() => {}} className="menuCheckBox flagPhotoCheckBox">
                            <span style={{ fontSize: 16, color: 'black' }}>Full Time</span>
                          </Checkbox>
                        </Col>
                      </Row>
                      <Row type="flex" justify="start" style={{ marginBottom: 10 }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Checkbox onChange={() => {}} className="menuCheckBox flagPhotoCheckBox">
                            <span style={{ fontSize: 16, color: 'black' }}>Red Card</span>
                          </Checkbox>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Checkbox onChange={() => {}} className="menuCheckBox flagPhotoCheckBox">
                            <span style={{ fontSize: 16, color: 'black' }}>Yellow Card</span>
                          </Checkbox>
                        </Col>
                      </Row>
                      <Row type="flex" justify="start">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Checkbox onChange={() => {}} className="menuCheckBox flagPhotoCheckBox">
                            <span style={{ fontSize: 16, color: 'black' }}>Other</span>
                          </Checkbox>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Edit Placement" key="2">
                      <Row type="flex" justify="start" style={{ marginBottom: 10 }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0 }}>
                          <Row type="flex" justify="start">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Checkbox
                                onChange={() => {}}
                                className="menuCheckBox flagPhotoCheckBox"
                              >
                                <span style={{ fontSize: 16, color: 'black' }}>Goal</span>
                              </Checkbox>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Input
                                placeholder="0.00"
                                style={{ width: '70%', background: 'whitesmoke' }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Row type="flex" justify="start">
                            <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                              <Checkbox
                                onChange={() => {}}
                                className="menuCheckBox flagPhotoCheckBox"
                              >
                                <span style={{ fontSize: 16, color: 'black' }}>Penalty</span>
                              </Checkbox>
                            </Col>

                            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                              <Input
                                placeholder="0.00"
                                style={{ width: '100%', background: 'whitesmoke' }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row type="flex" justify="start" style={{ marginBottom: 10 }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0 }}>
                          <Row type="flex" justify="start">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Checkbox
                                onChange={() => {}}
                                className="menuCheckBox flagPhotoCheckBox"
                              >
                                <span style={{ fontSize: 16, color: 'black' }}>Half Time</span>
                              </Checkbox>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Input
                                placeholder="0.00"
                                style={{ width: '70%', background: 'whitesmoke' }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Row type="flex" justify="start">
                            <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                              <Checkbox
                                onChange={() => {}}
                                className="menuCheckBox flagPhotoCheckBox"
                              >
                                <span style={{ fontSize: 16, color: 'black' }}>Full Time</span>
                              </Checkbox>
                            </Col>

                            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                              <Input
                                placeholder="0.00"
                                style={{ width: '100%', background: 'whitesmoke' }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row type="flex" justify="start" style={{ marginBottom: 10 }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0 }}>
                          <Row type="flex" justify="start">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Checkbox
                                onChange={() => {}}
                                className="menuCheckBox flagPhotoCheckBox"
                              >
                                <span style={{ fontSize: 16, color: 'black' }}>Red Card</span>
                              </Checkbox>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Input
                                placeholder="0.00"
                                style={{ width: '70%', background: 'whitesmoke' }}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Row type="flex" justify="start">
                            <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                              <Checkbox
                                onChange={() => {}}
                                className="menuCheckBox flagPhotoCheckBox"
                              >
                                <span style={{ fontSize: 16, color: 'black' }}>Yellow Card</span>
                              </Checkbox>
                            </Col>

                            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                              <Input
                                placeholder="0.00"
                                style={{ width: '100%', background: 'whitesmoke' }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row type="flex" justify="start" style={{ marginBottom: 10 }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0 }}>
                          <Row type="flex" justify="start">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Checkbox
                                onChange={() => {}}
                                className="menuCheckBox flagPhotoCheckBox"
                              >
                                <span style={{ fontSize: 16, color: 'black' }}>Other</span>
                              </Checkbox>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Input
                                placeholder="0.00"
                                style={{ width: '70%', background: 'whitesmoke' }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </TabPane>
                  </Tabs>
                </CardBox>
              </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <CardBox
                heading="Summary"
                headerStyle={{ fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 'bold' }}
                style={{ width: '70%' }}
                cardBottomBorder="summaryBottomBorder"
                subHeader
              >
                <Row type="flex" justify="start">
                  <Col
                    xs={18}
                    sm={18}
                    md={18}
                    lg={18}
                    xl={18}
                    style={{ padding: '0px' }}
                    className="summaryCol"
                  >
                    <div>
                      <h6 style={{ marginBottom: 16 }}>Reach & Budget</h6>
                      <p>
                        Location
                        <span> United Kingdom </span>
                      </p>
                      <p>
                        Gender
                        <span> Female </span>
                      </p>
                      <p>
                        Language
                        <span> English, French</span>
                      </p>
                    </div>
                    <div>
                      <h6 style={{ marginBottom: 6, marginTop: 35, fontWeight: 600 }}>
                        Reach is 20,000 people
                      </h6>
                      <Progress percent={90} showInfo={false} />
                      <h6 style={{ marginBottom: 35, fontWeight: 600 }}>Exposure is 1,200,000</h6>
                    </div>
                    <div>
                      <p>
                        Placement
                        <span> Automatic </span>
                      </p>
                      <p>
                        Lifetime Budget
                        <span> 10k </span>
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBox>
            </Col>
          </Row>
        </>
      )}

      {current === 2 && (
        <div className="headerContainer">
          <h1>Review & Publish</h1>
          <h3>Confirm your setup and publish</h3>
        </div>
      )}

      <AddVenueModal
        openAddVenueModal={openAddVenueModal}
        onCloseModal={onAddVenueModalClose}
        modalStyle={AddVenueModalStyle}
      />
      <Row type="flex" justify="space-around">
        <Col xs={12} sm={12} md={16} lg={16} xl={16}>
          <Steps current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Col>

        <Col xs={8} sm={8} md={4} lg={4} xl={4}>
          <div className="steps-action">
            {current > 0 && (
              <Button style={{ marginLeft: 8 }} onClick={() => prev()} size="large">
                Back
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => next()}
                size="large"
                style={{ background: '#038fde' }}
              >
              <a href='#mona'>
                Next
                </a>
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => {}}
                style={{ background: '#038fde' }}
                size="large"
              >
                Publish
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CreateCampaign;
