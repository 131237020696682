import styled from 'styled-components';
import { Button, Modal } from 'antd';

export const Wrapper = styled.div``;
export const ActionWrapper = styled.div`
  display: none;
  @media (max-width: 575px) {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
`;

export const CropperWrapper = styled.div`
  position: relative;
  height: 400px;
  width: 400px;
  background: #ffffff;
`;

export const StyledButton = styled(Button)`
  margin-top: 10px;
`;

export const StyledDownloadButton = styled(Button)`
  margin-bottom: 4px !important;
  width: 36px !important;
  width: 36px !important;
`;

export const SeatDetailsContainer = styled.div`
  border-bottom: solid 1px #d9d9d9;
  padding-bottom: 20px;
  margin-bottom: 15px;
  text-align: center !important;
`;

export const SeatDetailsList = styled.ul`
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin: 0 -5px;
`;

export const SeatDetailsListItem = styled.li`
  color: #8c8c8c;
  font-size: 14px;
  padding: 0 5px;
  width: 25%;
  &:not(:first-child) {
    border-left: solid 1px #d9d9d9;
  }
`;

export const SeatDetailsTitle = styled.span`
  display: block;
  color: #262626;
  font-size: 14px;
`;

export const SeatDetailsValue = styled.span``;

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    display: none;
  }
  .ant-modal-content {
    width: 400px;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-footer {
    display: flex;
  }
`;

export const StyledFooterButton = styled(Button)`
  width: 50%;
`;
