import styled from 'styled-components';

export const StadiumLogoContainer = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`;
