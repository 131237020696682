import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Row, Col } from 'antd';

const CardBox = ({
  heading,
  children,
  styleName,
  childrenStyle,
  childrenMarginBottomStyle,
  margin,
  style,
  headerStyle,
  onEditClick,
  editIcon,
  cardBottomBorder,
  subHeader,
}) => {
  return (
    <div className={`gx-card ${styleName}`} style={{ margin, ...style }}>
      {heading && (
        <>
          {!editIcon && (
            <div className={`gx-card-head ${cardBottomBorder}`}>
              <h3
                className="gx-card-title"
                style={{ fontSize: '16px', fontWeight: '500', ...headerStyle }}
              >
                {heading}
              </h3>
            </div>
          )}
          {editIcon && (
            <Row type="flex" justify="start" style={{ paddingTop: '0px' }}>
              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                <div className="gx-card-head" style={{ paddingLeft: '8px' }}>
                  <h3
                    className="gx-card-title"
                    style={{ fontSize: '16px', fontWeight: '500', ...headerStyle }}
                  >
                    {heading}
                  </h3>
                </div>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <div
                  onClick={onEditClick}
                  className="gx-card-head"
                  style={{ paddingLeft: '8px', fontSize: '16px', cursor: 'pointer' }}
                >
                  <Icon type="edit" style={{ fontSize: '26px' }} />
                </div>
              </Col>
            </Row>
          )}
        </>
      )}
      {subHeader && (
        <div
          style={{
            marginTop: 14,
            paddingLeft: 24,
            fontSize: 12,
            color: 'grey',
            fontWeight: 500,
            borderBottom: '1px solid #e8e8e8',
          }}
        >
          <p>
            Activation Name
            <span style={{ color: '#038fde' }}> Sports </span>
          </p>
          <p>
            Format
            <span style={{ color: '#038fde' }}> Animated </span>
          </p>
        </div>
      )}
      <div
        className={`gx-card-body ${childrenStyle} ${childrenMarginBottomStyle}`}
        style={{ paddingTop: '0px' }}
      >
        {children}
      </div>
    </div>
  );
};

export default CardBox;

CardBox.propTypes = {
  children: PropTypes.node.isRequired,
};

CardBox.defaultProps = {
  styleName: '',
  childrenStyle: '',
};
