import React, { useState } from 'react';
import { Tabs, Row, Col, Button, Switch, Divider, Form, Input, Select, Upload, Icon } from 'antd';
import CardBox from '../../../components/CardBox';
import '../styles.less';
import Users from '../Users';
import BasicModal from '../../../components/Modal/BasicModal';

const { TabPane } = Tabs;

const PersonalDetailsEditModal = ({ openPersonalDetailsModal, modalStyle, onCloseModal }) => {
  return (
    <BasicModal open={openPersonalDetailsModal} modalStyle={modalStyle} onCloseModal={onCloseModal}>
      <div>
        <h3 style={{ paddingTop: '20px' }}>Edit Personal Details</h3>
        <Form>
          <div>
            <Row type="flex" justify="start">
              <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: '0px' }}>
                  <p className="modalFormLabel">Name</p>
                  <Form.Item className="modalFormItem">
                    <Input placeholder="Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: '0px' }}>
                  <p className="modalFormLabel">Email</p>
                  <Form.Item className="modalFormItem">
                    <Input placeholder="Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: '0px' }}>
                  <p className="modalFormLabel">Role</p>
                  <Form.Item className="modalFormItem">
                    <Select className="" placeholder="Organization Role" onChange={() => {}} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: '0px' }}>
                  <p className="modalFormLabel">Phone Number</p>
                  <Form.Item className="modalFormItem">
                    <Input placeholder="Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: '0px' }}>
                  <p className="modalFormLabel">Time Zone</p>
                  <Form.Item className="modalFormItem">
                    <Input placeholder="Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: '0px' }}>
                  <p className="modalFormLabel">Currency</p>
                  <Form.Item className="modalFormItem">
                    <Input placeholder="Name" />
                  </Form.Item>
                </Col>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <p className="cardBoxParagraph">Profile Photo</p>
                <Upload name="avatar" listType="picture-card" className="avatar-uploader">
                  <div>
                    <Icon type="plus" />
                    <div className="ant-upload-text">Upload</div>
                  </div>
                </Upload>
              </Col>
            </Row>
          </div>
          <div className="modalButtonContainer">
            <Button className="editCancelModalButton" onClick={onCloseModal}>
              Cancel
            </Button>
            <Button className="editSaveModalButton">Save</Button>
          </div>
        </Form>
      </div>
    </BasicModal>
  );
};

const OrganizationalDetailsEditModal = ({
  openOrganizationaDetailsModal,
  modalStyle,
  onCloseModal,
}) => {
  return (
    <BasicModal
      open={openOrganizationaDetailsModal}
      modalStyle={modalStyle}
      onCloseModal={onCloseModal}
    >
      <div>
        <h3 style={{ paddingTop: '20px' }}>Edit Organization Details</h3>
        <Form>
          <div style={{ marginBottom: '70px' }}>
            <Row type="flex" justify="start">
              <Col xs={8} sm={8} md={24} lg={24} xl={24} style={{ padding: '0px' }}>
                <Row type="flex" justify="start" style={{ paddingTop: '12px' }}>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '0px' }}>
                    <p className="modalFormLabel">Business Name</p>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <p className="modalFormLabel">Email</p>
                  </Col>
                </Row>

                <div style={{ display: 'flex' }}>
                  <Form.Item className="modalFormItem" style={{ marginRight: '3%' }}>
                    <Input placeholder="Name" />
                  </Form.Item>
                  <Form.Item className="modalFormItem">
                    <Input placeholder="Email" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={8} sm={8} md={24} lg={24} xl={24} style={{ padding: '0px' }}>
                <Row type="flex" justify="start" style={{ paddingTop: '12px' }}>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '0px' }}>
                    <p className="modalFormLabel">Business Address</p>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <p className="modalFormLabel">Post Code</p>
                  </Col>
                </Row>

                <div style={{ display: 'flex' }}>
                  <Form.Item className="modalFormItem" style={{ marginRight: '3%' }}>
                    <Input placeholder="Business Address" />
                  </Form.Item>
                  <Form.Item className="modalFormItem">
                    <Input placeholder="Postcode" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={8} sm={8} md={18} lg={18} xl={18} style={{ padding: '0px' }}>
                <p className="modalFormLabel">Type</p>
              </Col>
            </Row>
          </div>
          <div className="modalButtonContainer">
            <Button className="editCancelModalButton" onClick={onCloseModal}>
              Cancel
            </Button>
            <Button className="editSaveModalButton">Save</Button>
          </div>
        </Form>
      </div>
    </BasicModal>
  );
};

const OrganizationalDetailsEditModalStyle = {
  modal: {
    height: '445px',
    backgroundColor: '#f3f3f3',
    width: '820px',
    borderRadius: '10px',
  },
  closeButton: {
    margin: '30px',
    cursor: 'pointer',
  },
  closeIcon: {
    fill: '#C8C8C8',
    width: '22px',
  },
  transitionExit: {
    opacity: '1',
  },
};

const PersonalDetailsEditModalStyle = {
  modal: {
    height: '690px',
    backgroundColor: '#f3f3f3',
    width: '660px',
    borderRadius: '10px',
  },
  closeButton: {
    margin: '30px',
    cursor: 'pointer',
  },
  closeIcon: {
    fill: '#C8C8C8',
    width: '22px',
  },
  transitionExit: {
    opacity: '1',
  },
};

const AccountDetails = () => {
  const [openPersonalDetailsModal, setopenPersonalDetailsModal] = useState(false);
  const [openOrganizationaDetailsModal, setopenOrganizationaDetailsModal] = useState(false);
  const onPersonalDetailEditClick = () => {
    setopenPersonalDetailsModal(true);
  };

  const onPersonalDetailModalClose = () => {
    setopenPersonalDetailsModal(false);
  };

  const onOrganizationalDetailEditClick = () => {
    setopenOrganizationaDetailsModal(true);
  };

  const onOrganizationalDetailModalClose = () => {
    setopenOrganizationaDetailsModal(false);
  };

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab={<h1 level={3}>Account Details</h1>} key="1">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardBox
            heading="Personal Details"
            style={{ marginTop: '12px', width: '55%' }}
            headerStyle={{ fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 'bold' }}
            editIcon
            onEditClick={onPersonalDetailEditClick}
          >
            <Row type="flex" justify="start">
              <Col xs={18} sm={18} md={18} lg={18} xl={18} style={{ padding: '0px' }}>
                <p className="cardBoxParagraph">
                  Name
                  <span> Vohla Paulovich </span>
                </p>
                <p className="cardBoxParagraph">
                  Email Address
                  <span> Vohla@exmaple.com </span>
                </p>
                <p className="cardBoxParagraph">
                  Role
                  <span> Admin </span>
                </p>
                <p className="cardBoxParagraph">
                  Phone Number
                  <span> 07493363812 </span>
                </p>
                <p className="cardBoxParagraph">
                  Time Zone
                  <span> Europe/London </span>
                </p>
                <p className="cardBoxParagraph">
                  Currency
                  <span> GBP </span>
                </p>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <p className="cardBoxParagraph">Profile Photo</p>
                <Upload name="avatar" listType="picture-card" className="avatar-uploader">
                  <div>
                    <Icon type="plus" />
                    <div className="ant-upload-text">Upload</div>
                  </div>
                </Upload>
              </Col>
            </Row>
          </CardBox>
          <CardBox
            heading="Organisation Details"
            style={{ marginTop: '12px', width: '42%' }}
            headerStyle={{ fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 'bold' }}
            editIcon
            onEditClick={onOrganizationalDetailEditClick}
          >
            <Row type="flex" justify="start">
              <Col xs={18} sm={18} md={18} lg={18} xl={18} style={{ padding: '0px' }}>
                <p className="cardBoxParagraph">
                  Business Name
                  <span> Snapify </span>
                </p>
                <p className="cardBoxParagraph">
                  Email Address
                  <span> snapify@exmaple.com </span>
                </p>
                <p className="cardBoxParagraph">
                  Business Adress
                  <span> Liverpool Street </span>
                </p>
                <p className="cardBoxParagraph">
                  Post Code
                  <span> EC2A 4LG </span>
                </p>
                <p className="cardBoxParagraph">
                  Type
                  <span> Agency </span>
                </p>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} />
            </Row>
          </CardBox>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardBox
            heading="Accounts Settings"
            style={{ marginTop: '12px', width: '55%' }}
            headerStyle={{ fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 'bold' }}
          >
            <Row type="flex" justify="start">
              <Col xs={18} sm={18} md={18} lg={14} xl={14} style={{ padding: '0px' }}>
                <p className="cardBoxParagraph">Password</p>
                <div className="resetPasswordButtonDiv">
                  <Button className="accountSettingsButton">Reset Password</Button>
                  <Divider style={{ margin: '0px' }} />
                </div>
                <p className="cardBoxParagraph">Deactivate Account</p>
                <span>
                  If you no longer need your account at snapify workspace, you can deactivate your
                  account.
                </span>
                <div className="">
                  <Button className="accountSettingsButton deactivateButtonDiv">
                    Deactivate my Account
                  </Button>
                </div>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <p className="cardBoxParagraph" />
              </Col>
            </Row>
          </CardBox>
          <CardBox
            heading="Notification Settings"
            style={{ marginTop: '12px', width: '42%' }}
            headerStyle={{ fontFamily: 'sans-serif', fontSize: '16px', fontWeight: 'bold' }}
          >
            <Row type="flex" justify="start">
              <Col xs={18} sm={18} md={18} lg={18} xl={18} style={{ padding: '0px' }}>
                <div className="noticationContainer">
                  <p className="cardBoxParagraph">Campaigns Details</p>
                  <Switch
                    defaultChecked
                    onChange={() => {}}
                    style={{ backgroundColor: '#0099cc' }}
                  />
                </div>
                <div className="noticationContainer">
                  <p className="cardBoxParagraph">New Invoice</p>
                  <Switch
                    defaultChecked
                    onChange={() => {}}
                    style={{ backgroundColor: '#0099cc' }}
                  />
                </div>
                <div className="noticationContainer">
                  <p className="cardBoxParagraph">New Invoice</p>
                  <Switch
                    defaultChecked
                    onChange={() => {}}
                    style={{ backgroundColor: '#0099cc' }}
                  />
                </div>
                <div className="noticationContainer">
                  <p className="cardBoxParagraph">Members Activities</p>
                  <Switch
                    defaultChecked
                    onChange={() => {}}
                    style={{ backgroundColor: '#0099cc' }}
                  />
                </div>
                <div className="noticationContainer">
                  <p className="cardBoxParagraph">Members Activities</p>
                  <Switch
                    defaultChecked
                    onChange={() => {}}
                    style={{ backgroundColor: '#0099cc' }}
                  />
                </div>
                <div className="noticationContainer">
                  <p className="cardBoxParagraph">Members Activities</p>
                  <Switch
                    defaultChecked
                    onChange={() => {}}
                    style={{ backgroundColor: '#0099cc' }}
                  />
                </div>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} />
            </Row>
          </CardBox>
        </div>
        <PersonalDetailsEditModal
          openPersonalDetailsModal={openPersonalDetailsModal}
          onCloseModal={onPersonalDetailModalClose}
          modalStyle={PersonalDetailsEditModalStyle}
        />
        <OrganizationalDetailsEditModal
          openOrganizationaDetailsModal={openOrganizationaDetailsModal}
          onCloseModal={onOrganizationalDetailModalClose}
          modalStyle={OrganizationalDetailsEditModalStyle}
        />
      </TabPane>
      <TabPane tab={<h1 level={3}>Users</h1>} key="2">
        <div className="userSectionContainer">
          <Users />
        </div>
      </TabPane>
    </Tabs>
  );
};

export default AccountDetails;
