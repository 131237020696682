const copyLinkToClipBoard = () => {
  const copyText = document.getElementById('copyToClipBoard');
  copyText.select();
  copyText.setSelectionRange(0, 99999);
  document.execCommand('copy');
};

export default {
  copyLinkToClipBoard,
};
