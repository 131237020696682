const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export default async function getCroppedImg(imageSrc, overlaySrc, pixelCrop) {
  const image = await createImage(imageSrc);
  const overlay = await createImage(overlaySrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    image.width,
    image.height,
  );

  ctx.drawImage(overlay, 0, 0, overlay.width, overlay.height, 0, 0, overlay.width, overlay.height);

  return canvas.toDataURL('image/jpeg');
}
