/* eslint-disable no-undef */
import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { Route, Switch } from 'react-router-dom';
import 'assets/vendors/style';
import 'styles/wieldy.less';
import configureStore, { history, persistor } from './appRedux/store';
import Routes from './routes';

const store = configureStore;

const NextApp = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={Routes} />
        </Switch>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

export default NextApp;
