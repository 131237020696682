/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import dotenv from 'dotenv';
import Amplify from 'aws-amplify';

import NextApp from './NextApp';
import registerServiceWorker from './registerServiceWorker';
import { AppContainer } from 'react-hot-loader';

dotenv.config();

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${process.env.REACT_APP_HOST_URL}/social`,
      redirectSignOut: `${process.env.REACT_APP_HOST_URL}/signout`,
      responseType: 'code',
    },
  },
});

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById('root'),
  );
};

registerServiceWorker();

render(NextApp);
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
