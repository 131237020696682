import campaignActionTypes from './constants/campaignActionTypes';

export const setCampaignFilter = payload => ({
  type: campaignActionTypes.SET_CAMPAIGN_FILTER,
  payload,
});

export const removeCampaignFilter = () => ({
  type: campaignActionTypes.REMOVE_CAMPAIGN_FILTER,
});
