/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Layout, Popover } from 'antd';
import { Link } from 'react-router-dom';

import CustomScrollbars from 'util/CustomScrollbars';
import languageData from './languageData';
import { switchLanguage, toggleCollapsedSideNav } from '../../appRedux/actions/Setting';
import UserInfo from 'components/UserInfo';
import AppNotification from 'components/AppNotification';
import MailNotification from 'components/MailNotification';
import Auxiliary from 'util/Auxiliary';
import '../styles.less';

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from '../../constants/ThemeSetting';
import { useDispatch, useSelector } from 'react-redux';

const { Header } = Layout;

const Topbar = () => {
  const { locale, width, navCollapsed, navStyle, pathname } = useSelector(
    ({ settings }) => settings,
  );

  const { location } = useSelector(({ router }) => router);

  const dispatch = useDispatch();

  let pageTitle;

  if (location.pathname === '/mycontent/contentmanagement') {
    pageTitle = 'Content Management';
  } else if (location.pathname === '/mycampaigns/mycampaigns') {
    pageTitle = 'My Campaigns';
  } else if (location.pathname.includes('/mycampaigns/managecampaign')) {
    pageTitle = 'Campaign Management';
  } else if (location.pathname.includes('/mycampaigns/createcampaign')) {
    pageTitle = 'Create Campaign';
  }

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={() => dispatch(switchLanguage(language))}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={require('assets/images/snaptivity_Logo.png')} className="topBarImgClass" />
      </Link>
      <h1 className="navTitle">{pageTitle}</h1>
    </Header>
  );
};

export default Topbar;
