import React from "react";
import styled from "styled-components";
import { Spin } from "antd";

const SpinnerWrapper = styled.div`
  height: calc(100vh);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Spinner = () => {
    return (
        <SpinnerWrapper>
            <Spin size="large" />
        </SpinnerWrapper>
    );
};

export default Spinner;
