/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Icon, Input, Button, Row, Col, Drawer, Spin } from 'antd';
import debounce from 'lodash.debounce';
import InfiniteScroll from 'react-infinite-scroller';
import uniqWith from 'lodash/uniqWith';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import Img from 'react-image';
import FooterButtons from '../../../../components/Buttons/FooterButtons';
import Thumbnails from '../../../../components/Thumbnails/Thumbnails';
import modalStyle from '../../../../shared/base/modalStyle';
import ContentManagementUtils from '../../../../util/ContentManagementUtils';

import '../styles.less';
import SocialMediaSharingButtons from '../../../../components/Buttons/SocialMediaSharingButtons';
import Filters from '../../../../components/Filters/Filters';
import { MOMENT_CODES } from '../../../../features/photos/constants';

class TabOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      modalImage: '',
      moments: [],
      loaded: false,
      showDownloadSection: false,
      showShareSection: false,
      imageUrl: '',
      hasMoreItems: true,
      photoState: [],
      loadMoreCalled: false,
      filtersAdded: props.filtersApplied,
      momentTags: [],
      eventDetails: [],
      loadMoreFilteredItems: false,
      drawerVisible: false,
      pageNumber: 1,
      displayLoader: false,
      nextToken: props.nextToken,
      seatDetails: {
        stand: '',
        block: '',
        row: '',
        seat: '',
      },
      seatID: '',
      disableBlock: true,
      disableRow: true,
      disableSeat: true,
      blockValue: 'Block',
      rowValue: 'Row',
      seatValue: 'Seat',
      standValue: 'Stand',
      goalChecked: false,
      penChecked: false,
      redcardChecked: false,
      yellowCardChecked: false,
      fullTimeChecked: false,
      halfTimeChecked: false,
      otherChecked: false,
      setSpinner: false,
      removePana: this.props.removeFav,
      remount: props.renderTabOne,
      eventDefaultValue: [],
    };
  }

  componentDidMount() {
    if (this.state.filtersAdded) {
      this.setState({
        filtersAdded: false,
        moments: [],
        eventDetails: [],
        seatID: '',
      });
    }

    if (this.props.campaignFilter.data) {
      this.setState({
        eventDetails: this.props.campaignFilter.data.eventID,
      });

      this.timer = setTimeout(() => {
        this.onApplyFilterClick();
      }, 2500);
    } else {
      this.props.getFavoritePhotos({
        data: {
          venueID: 'jca',
          updatePhotos: true,
          getSummary: true,
          token: this.props.token,
        },
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.updatePhotos && (!state.filtersAdded || !state.loadMoreCalled)) {
      return {
        photoState: props.favoritePhotos,
        hasMoreItems: true,
        filterScrollActive: true,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.updateFilteredContent || this.state.updateFilteredContent) &&
      this.state.filtersAdded
    ) {
      const { favoritePhotos } = this.props;
      this.setState({
        photoState: favoritePhotos,
        updateFilteredContent: false,
      });
    }
    if (prevProps.resetMoment !== this.props.resetMoment && this.props.resetMoment) {
      if (this.props.campaignFilter.data) {
        const { eventDetails } = this.state;
        const { venueInfo } = this.props;
        const addVenues = [];
        const venuesFullDetails = [];
        venueInfo.forEach(venue => {
          if (eventDetails.includes(`${venue.event_id}~`)) {
            addVenues.push(`${venue.team_home} v ${venue.team_away}`);
            venuesFullDetails.push({
              label: `${venue.event_id}~`,
              value: `${venue.team_home} v ${venue.team_away}`,
            });
          }
        });
        this.setState({
          momentTags: [...addVenues],
          venuesFullDetails,
          moments: [],
          seatID: '',
          disableBlock: true,
          disableRow: true,
          disableSeat: true,
          blockValue: 'Block',
          rowValue: 'Row',
          seatValue: 'Seat',
          standValue: 'Stand',
          goalChecked: false,
          penChecked: false,
          redcardChecked: false,
          yellowCardChecked: false,
          fullTimeChecked: false,
          halfTimeChecked: false,
          otherChecked: false,
        });
      } else {
        this.setState({
          moments: [],
          eventID: [],
          seatID: '',
          momentTags: [],
          disableBlock: true,
          disableRow: true,
          disableSeat: true,
          blockValue: 'Block',
          rowValue: 'Row',
          seatValue: 'Seat',
          standValue: 'Stand',
          goalChecked: false,
          penChecked: false,
          redcardChecked: false,
          yellowCardChecked: false,
          fullTimeChecked: false,
          halfTimeChecked: false,
          otherChecked: false,
        });
      }
    }
    if (
      prevProps.nextToken !== undefined &&
      prevProps.nextToken !== this.props.nextToken &&
      !this.props.fetchMore
    ) {
      this.setState({
        setSpinner: false,
      });
    }

    if (
      prevProps.removeFav !== this.props.removeFav &&
      (this.state.filtersAdded || this.state.loadMoreCalled)
    ) {
      const { favoritePhotos } = this.props;

      this.timer = setTimeout(() => {
        this.setState({
          photoState: favoritePhotos,
        });
      }, 1000);
    }

    if (
      prevProps.nextToken !== undefined &&
      prevProps.nextToken !== this.props.nextToken &&
      !this.state.filtersAdded &&
      this.state.loadMoreCalled &&
      (!this.props.removeFav || !prevState.removeFav)
    ) {
      const { favoritePhotos } = this.props;

      const mergedStates = [...prevState.photoState, ...favoritePhotos];
      const uniqPhotos = uniqWith(mergedStates, (a, b) => a.my_photo === b.my_photo);

      this.setState({
        photoState: uniqPhotos,
      });
    }

    if (
      prevProps.nextToken !== undefined &&
      prevProps.nextToken !== this.props.nextToken &&
      this.state.filtersAdded &&
      prevState.loadMoreFilteredItems
    ) {
      if (!this.state.filterScrollActive) {
        const { favoritePhotos } = this.props;
        this.setState({
          photoState: favoritePhotos,
          displayLoader: false,
        });
      } else {
        const { favoritePhotos } = this.props;

        const mergedStates = [...prevState.photoState, ...favoritePhotos];
        const uniqPhotos = uniqWith(mergedStates, (a, b) => a.my_photo === b.my_photo);

        this.setState({
          photoState: uniqPhotos,
        });
      }
    }
  }

  loadMore = () => {
    if (!this.props.fetchMore) {
      this.setState({
        hasMoreItems: false,
        filterScrollActive: false,
        setSpinner: false,
      });
    } else {
      if (!this.state.loadMoreFilteredItems) {
        this.setState({
          loadMoreCalled: true,
        });
        const { getFavoritePhotos } = this.props;

        getFavoritePhotos({
          data: {
            venueID: 'jca',
            pageNumber: this.props.nextToken,
            updatePhotos: false,
            token: this.props.token,
          },
        });
      }
      if (this.state.loadMoreFilteredItems) {
        this.setState({
          filterScrollActive: true,
          displayLoader: false,
        });

        const { moments, seatID, eventDetails } = this.state;
        const momentID = moments.join('');
        const eventID = eventDetails.join('');

        const { getFavoritePhotos } = this.props;

        getFavoritePhotos({
          data: {
            venueID: 'jca',
            momentID,
            seatID,
            eventID,
            pageNumber: this.props.nextToken,
            updatePhotos: false,
            token: this.props.token,
          },
        });
      }
    }
  };

  onFilterResetClick = () => {
    const { getFavoritePhotos } = this.props;
    const { momentTags, eventDetails } = this.state;
    if (this.props.campaignFilter.data) {
      this.props.removeCampaignFilter();
    }

    if (momentTags.length || eventDetails.length) {
      getFavoritePhotos({
        data: {
          venueID: 'jca',
          updatePhotos: true,
          token: this.props.token,
          getSummary: true,
        },
      });

      this.timer = setTimeout(() => {
        this.setState({
          loadMoreFilteredItems: false,
          loadMoreCalled: false,
          filtersAdded: false,
          moments: [],
          momentTags: [],
          goalChecked: false,
          redcardChecked: false,
          fullTimeChecked: false,
          otherChecked: false,
          halfTimeChecked: false,
          yellowCardChecked: false,
          penChecked: false,
          seatDetails: {
            stand: '',
            block: '',
            row: '',
            seat: '',
          },
          standValue: 'Stand',
          blockValue: 'Block',
          rowValue: 'Row',
          seatValue: 'Seat',
          setSpinner: false,
          venuesFullDetails: [],
        });
      }, 3000);
    }
  };

  onOpenModal = image => {
    this.setState({
      open: true,
      modalImage: image,
      imageUrl: image,
    });
  };

  onCloseModal = () => {
    this.setState({
      open: false,
      modalImage: '',
      loaded: false,
      showDownloadSection: false,
      showShareSection: false,
      imageUrl: '',
    });
  };

  showModalImage = () => {
    this.setState({
      loaded: true,
    });
  };

  onClickDownload = () => {
    this.setState({
      showDownloadSection: true,
      showShareSection: false,
    });
  };

  onClickShare = () => {
    this.setState({
      showDownloadSection: false,
      showShareSection: true,
    });
  };

  onDownloadCancel = () => {
    this.setState({
      showDownloadSection: false,
    });
  };

  onShareCancel = () => {
    this.setState({
      showShareSection: false,
    });
  };

  onCopyLink = () => {
    ContentManagementUtils.copyLinkToClipBoard();
  };

  onEventChange = value => {
    this.setState({
      eventDetails: uniq([...this.state.eventDetails, ...value]),
    });
  };

  onTagClose = removeTags => {
    const { momentTags, moments, seatDetails, venuesFullDetails, eventDetails } = this.state;
    const { getFavoritePhotos } = this.props;
    const filterTag = momentTags.filter(item => item !== removeTags);

    const storeSeatDetailsValues = [];

    const filterMoment = moments.filter(moment => MOMENT_CODES[moment] !== removeTags);

    const filterEvent = [];

    const getFilteredVenue = venuesFullDetails.filter(event => event.value !== removeTags);

    venuesFullDetails.forEach(event => {
      if (
        this.props.campaignFilter.data &&
        this.props.campaignFilter.data.eventID.includes(event.label)
      ) {
        this.props.removeCampaignFilter();
      }

      if (event.value !== removeTags) filterEvent.push(event.label);
    });

    if (removeTags === 'Goal') {
      this.setState({ goalChecked: false });
    }
    if (removeTags === 'Red Card') {
      this.setState({ redcardChecked: false });
    }
    if (removeTags === 'Full Time') {
      this.setState({ fullTimeChecked: false });
    }
    if (removeTags === 'Other') {
      this.setState({ otherChecked: false });
    }
    if (removeTags === 'Half Time') {
      this.setState({ halfTimeChecked: false });
    }
    if (removeTags === 'Yellow Card') {
      this.setState({ yellowCardChecked: false });
    }
    if (removeTags === 'Penalty') {
      this.setState({ penChecked: false });
    }

    if (`Stand: ${seatDetails.stand}` === removeTags) {
      seatDetails.stand = '';

      this.setState({
        seatDetails,
        standValue: 'Stand',
      });
    }

    if (`Block: ${seatDetails.block}` === removeTags) {
      seatDetails.block = '';

      this.setState({
        seatDetails,
        blockValue: 'Block',
      });
    }

    if (`Row: ${seatDetails.row}` === removeTags) {
      seatDetails.row = '';

      this.setState({
        seatDetails,
        rowValue: 'Row',
      });
    }

    if (`Seat: ${seatDetails.seat}` === removeTags) {
      seatDetails.seat = '';

      this.setState({
        seatDetails,
        seatValue: 'Seat',
      });
    }

    this.setState({
      seatDetails,
      momentTags: filterTag,
      moments: filterMoment,
      eventDetails: filterEvent,
      setSpinner: false,
      venuesFullDetails: getFilteredVenue,
    });

    this.setState(prevState => {
      if (prevState.seatDetails.stand) {
        storeSeatDetailsValues.push(`${seatDetails.stand}_`);
      }

      if (prevState.seatDetails.block) {
        storeSeatDetailsValues.push(`${seatDetails.block}_`);
      }
      if (prevState.seatDetails.row) {
        storeSeatDetailsValues.push(`${seatDetails.row}_`);
      }
      if (prevState.seatDetails.seat) {
        storeSeatDetailsValues.push(`${seatDetails.seat}`);
      }

      const seatID = storeSeatDetailsValues.join('');

      const momentID = prevState.moments.join('');
      const eventID = prevState.eventDetails.join('');

      getFavoritePhotos({
        data: {
          venueID: 'jca',
          eventID,
          momentID,
          seatID,
          token: this.props.token,
          getSummary: true,
        },
      });
    });

    this.timer = setTimeout(() => {
      this.setState({
        updateFilteredContent: true,
        loadMoreFilteredItems: false,
      });
    }, 3000);
  };

  onMomentGoalChange = e => {
    const { moments } = this.state;
    if (e.target.checked) {
      this.setState({
        moments: [...moments, 'goal~'],
        goalChecked: true,
      });
    } else {
      const filteredMoment = moments.filter(item => item !== 'goal~');

      this.setState({
        moments: filteredMoment,
        goalChecked: false,
      });
    }
  };

  onMomentPenaltyChange = e => {
    const { moments } = this.state;
    if (e.target.checked) {
      this.setState({
        moments: [...moments, 'pen~'],
        penChecked: true,
      });
    } else {
      const filteredMoment = moments.filter(item => item !== 'pen~');

      this.setState({
        moments: filteredMoment,
        penChecked: false,
      });
    }
  };

  onMomentRedCardChange = e => {
    const { moments } = this.state;
    if (e.target.checked) {
      this.setState({
        moments: [...moments, 'redcard~'],
        redcardChecked: true,
      });
    } else {
      const filteredMoment = moments.filter(item => item !== 'redcard~');

      this.setState({
        moments: filteredMoment,
        redcardChecked: false,
      });
    }
  };

  onMomentYellowCardChange = e => {
    const { moments } = this.state;
    if (e.target.checked) {
      this.setState({
        moments: [...moments, 'yellowcard~'],
        yellowCardChecked: true,
      });
    } else {
      const filteredMoment = moments.filter(item => item !== 'yellowcard~');
      this.setState({
        moments: filteredMoment,
        yellowCardChecked: false,
      });
    }
  };

  onMomentHalfTimeChange = e => {
    const { moments } = this.state;
    if (e.target.checked) {
      this.setState({
        moments: [...moments, 'ht~'],
        halfTimeChecked: true,
      });
    } else {
      const filteredMoment = moments.filter(item => item !== 'ht~');
      this.setState({
        moments: filteredMoment,
        halfTimeChecked: false,
      });
    }
  };

  onMomentFullTimeChange = e => {
    const { moments } = this.state;
    if (e.target.checked) {
      this.setState({
        moments: [...moments, 'ft~'],
        fullTimeChecked: true,
      });
    } else {
      const filteredMoment = moments.filter(item => item !== 'ft~');
      this.setState({
        moments: filteredMoment,
        fullTimeChecked: false,
      });
    }
  };

  onMomentOtherChange = e => {
    const { moments } = this.state;
    if (e.target.checked) {
      this.setState({
        moments: [...moments, 'other~'],
        otherChecked: true,
      });
    } else {
      const filteredMoment = moments.filter(item => item !== 'other~');
      this.setState({
        moments: filteredMoment,
        otherChecked: false,
      });
    }
  };

  onStandChange = value => {
    const { seatDetails } = this.state;

    if (value.length) {
      seatDetails.stand = value;

      this.setState({
        seatDetails,
        disableBlock: false,
        blockValue: 'Block',
        seatValue: 'Seat',
        rowValue: 'Row',
      });
    }
  };

  onBlockChange = value => {
    const { seatDetails } = this.state;

    if (value.length) {
      seatDetails.block = value;

      this.setState({
        seatDetails,
        disableRow: false,
        blockValue: value,
        rowValue: 'Row',
        seatValue: 'Seat',
      });
    }
  };

  onRowChange = value => {
    const { seatDetails } = this.state;
    if (value.length) {
      seatDetails.row = value;

      this.setState({
        seatDetails,
        disableSeat: false,
        rowValue: value,
        seatValue: 'Seat',
      });
    }
  };

  onSeatChange = value => {
    const { seatDetails } = this.state;
    seatDetails.seat = value;

    this.setState({
      seatDetails,
      seatValue: value,
    });
  };
  onApplyFilterClick = () => {
    this.setState({
      setSpinner: false,
    });

    const { moments, seatDetails, eventDetails } = this.state;
    const { getFavoritePhotos, venueInfo } = this.props;

    const storeMomentValues = [];
    const storeSeatDetailsValues = [];
    const storeSeatDetailsTags = [];
    const addVenues = [];
    const venuesFullDetails = [];

    moments.forEach(moment => {
      if (MOMENT_CODES[moment]) {
        storeMomentValues.push(MOMENT_CODES[moment]);
      }
    });

    venueInfo.forEach(venue => {
      if (eventDetails.includes(`${venue.event_id}~`)) {
        addVenues.push(`${venue.team_home} v ${venue.team_away}`);
        venuesFullDetails.push({
          label: `${venue.event_id}~`,
          value: `${venue.team_home} v ${venue.team_away}`,
        });
      }
    });

    if (seatDetails.stand) {
      storeSeatDetailsValues.push(`${seatDetails.stand}_`);
      storeSeatDetailsTags.push(`Stand: ${seatDetails.stand}`);
    }
    if (seatDetails.block) {
      storeSeatDetailsValues.push(`${seatDetails.block}_`);
      storeSeatDetailsTags.push(`Block: ${seatDetails.block}`);
    }
    if (seatDetails.row) {
      storeSeatDetailsValues.push(`${seatDetails.row}_`);
      storeSeatDetailsTags.push(`Row: ${seatDetails.row}`);
    }
    if (seatDetails.seat) {
      storeSeatDetailsValues.push(`${seatDetails.seat}`);
      storeSeatDetailsTags.push(`Seat: ${seatDetails.seat}`);
    }

    const seatID = storeSeatDetailsValues.join('');
    const momentID = moments.join('');
    this.setState({
      momentTags: [...storeMomentValues, ...storeSeatDetailsTags, ...addVenues],
      seatID,
      venuesFullDetails,
    });

    const eventID = eventDetails.join('');
    if (!this.props.campaignFilter.data) {
      getFavoritePhotos({
        data: {
          venueID: 'jca',
          eventID,
          momentID,
          seatID,
          filtersApplied: true,
          token: this.props.token,
          getSummary: true,
        },
      });

      this.timer = setTimeout(() => {
        this.setState({
          displayLoader: true,
          updateFilteredContent: true,
        });
      }, 3000);
    }

    if (this.props.campaignFilter.data) {
      getFavoritePhotos({
        data: {
          venueID: 'jca',
          eventID,
          momentID,
          seatID,
          filtersApplied: true,
          token: this.props.token,
          getSummary: true,
          updatePhotos: true,
        },
      });
    }

    if (moments.length || storeSeatDetailsValues.length || eventDetails.length) {
      this.setState({ filtersAdded: true, loadMoreCalled: false });
    } else {
      this.setState({ filtersAdded: false, loadMoreFilteredItems: false, loadMoreCalled: true });
    }
  };

  showDrawer = () => {
    this.setState({
      drawerVisible: true,
    });
  };

  onDrawerClose = () => {
    this.setState({
      drawerVisible: false,
    });
  };

  callLoadMore = () => {
    this.setState({
      setSpinner: true,
    });

    if (this.state.filtersAdded) {
      this.setState({
        loadMoreFilteredItems: true,
      });
    }

    this.timer = setTimeout(() => {
      this.loadMore();
    }, 2500);
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const {
      showDownloadSection,
      showShareSection,
      photoState,
      open,
      imageUrl,
      modalImage,
      loaded,
      momentTags,
      moments,
      hasMoreItems,
      disableBlock,
      disableRow,
      disableSeat,
      blockValue,
      rowValue,
      seatValue,
      goalChecked,
      penChecked,
      redcardChecked,
      yellowCardChecked,
      fullTimeChecked,
      halfTimeChecked,
      otherChecked,
      setSpinner,
      standValue,
      eventDefaultValue,
      eventDetails,
      resetMoment,
    } = this.state;

    const {
      seat,
      row,
      stand,
      block,
      favoritePhotos,
      removeTopPicksFavoritePhotos,
      loadMoreCalled,
      venueInfo,
      postMyAsset,
      summary,
      getOverlay,
      overlays,
      getFavoritePhotos,
      seatID,
    } = this.props;

    let showPageLoader;

    const displayDownloadSection = showDownloadSection ? '' : 'none';
    const displayShareSection = showShareSection ? '' : 'none';

    let displayModalResponsiveView;
    const windowSize = window.innerWidth > 800;

    if (showDownloadSection === true || showShareSection === true) {
      displayModalResponsiveView = true;
    } else {
      displayModalResponsiveView = false;
    }

    return (
      <Row type="flex" justify="start">
        <Col xs={24} sm={24} md={24} lg={17} xl={17} style={{ padding: '0px' }}>
          <>
            <Row type="flex" justify="start">
              <Col xs={24} sm={24} md={24} lg={0} xl={0} style={{ padding: '0px' }}>
                <div className="filterSectionForSmallScreen">
                  <Button
                    onClick={this.showDrawer}
                    style={{ color: 'white', background: '#038fde' }}
                    id="toppicks_showdrawer_button"
                  >
                    Apply Filters
                  </Button>
                  <Drawer
                    width={640}
                    placement="right"
                    closable={false}
                    onClose={this.onDrawerClose}
                    visible={this.state.drawerVisible}
                  >
                    <Filters
                      momentTags={momentTags}
                      onTagClose={this.onTagClose}
                      onMomentGoalChange={this.onMomentGoalChange}
                      onMomentPenaltyChange={this.onMomentPenaltyChange}
                      onMomentHalfTimeChange={this.onMomentHalfTimeChange}
                      onMomentRedCardChange={this.onMomentRedCardChange}
                      onMomentYellowCardChange={this.onMomentYellowCardChange}
                      onMomentFullTimeChange={this.onMomentFullTimeChange}
                      onMomentOtherChange={this.onMomentOtherChange}
                      onApplyFilterClick={this.onApplyFilterClick}
                      row={row}
                      seat={seat}
                      block={block}
                      stand={stand}
                      onStandChange={this.onStandChange}
                      onBlockChange={this.onBlockChange}
                      onSeatChange={this.onSeatChange}
                      onRowChange={this.onRowChange}
                      onEventChange={this.onEventChange}
                      disableBlock={disableBlock}
                      disableRow={disableRow}
                      disableSeat={disableSeat}
                      standValue={standValue}
                      blockValue={blockValue}
                      rowValue={rowValue}
                      seatValue={seatValue}
                      goalChecked={goalChecked}
                      penChecked={penChecked}
                      redcardChecked={redcardChecked}
                      yellowCardChecked={yellowCardChecked}
                      fullTimeChecked={fullTimeChecked}
                      halfTimeChecked={halfTimeChecked}
                      otherChecked={otherChecked}
                      onFilterResetClick={this.onFilterResetClick}
                      venueInfo={venueInfo}
                      eventDefaultValue={eventDefaultValue}
                      eventSelectRef={this.eventSelectRef}
                      summary={summary}
                      photoState={photoState}
                      componentName="toppicks"
                    />
                  </Drawer>
                </div>
              </Col>
            </Row>
          </>

          <Spin spinning={!photoState.length}>
            <div className="tabContent tabOneContentHeight">
              <InfiniteScroll
                loadMore={debounce(this.callLoadMore, 500)}
                hasMore={hasMoreItems}
                useWindow={false}
                isReverse={false}
                initialLoad={false}
                threshold={1}
              >
                <>
                  <div className="tabOneFlexWrapper">
                    {photoState.map(photo => {
                      return (
                        <div key={`${photo.my_photo}`} className="thumbnailWrapper">
                          <Thumbnails
                            src={photo.my_photo}
                            type="star"
                            seat_id={photo.seat_id}
                            event_id={photo.event_id}
                            theme="filled"
                            showIcon={true}
                            showCursorPointer
                            removeTopPicksFavoritePhotos={removeTopPicksFavoritePhotos}
                            photoState={photoState}
                            overlay={photo.overlay}
                            photo={photo.photo}
                            postMyAsset={postMyAsset}
                            getOverlay={getOverlay}
                            overlays={overlays}
                            getFavoritePhotos={getFavoritePhotos}
                            eventID={eventDetails}
                            moments={moments}
                            seatID={seatID}
                            componentName="toppicks"
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              </InfiniteScroll>
              <div className="spinContainer">
                <Spin spinning={setSpinner} delay={0} style={{ fontSize: 18 }} />
              </div>
            </div>
          </Spin>
        </Col>

        <Col xs={6} sm={8} md={9} lg={6} xl={6} className="filtersCol">
          <div className="filterSectionForLargeScreen">
            <Filters
              momentTags={momentTags}
              onTagClose={this.onTagClose}
              onMomentGoalChange={this.onMomentGoalChange}
              onMomentPenaltyChange={this.onMomentPenaltyChange}
              onMomentHalfTimeChange={this.onMomentHalfTimeChange}
              onMomentRedCardChange={this.onMomentRedCardChange}
              onMomentYellowCardChange={this.onMomentYellowCardChange}
              onMomentFullTimeChange={this.onMomentFullTimeChange}
              onMomentOtherChange={this.onMomentOtherChange}
              onApplyFilterClick={this.onApplyFilterClick}
              row={row}
              seat={seat}
              block={block}
              stand={stand}
              onStandChange={this.onStandChange}
              onBlockChange={this.onBlockChange}
              onSeatChange={this.onSeatChange}
              onRowChange={this.onRowChange}
              onEventChange={this.onEventChange}
              disableBlock={disableBlock}
              disableRow={disableRow}
              disableSeat={disableSeat}
              blockValue={blockValue}
              rowValue={rowValue}
              seatValue={seatValue}
              goalChecked={goalChecked}
              penChecked={penChecked}
              redcardChecked={redcardChecked}
              yellowCardChecked={yellowCardChecked}
              fullTimeChecked={fullTimeChecked}
              halfTimeChecked={halfTimeChecked}
              otherChecked={otherChecked}
              standValue={standValue}
              onFilterResetClick={this.onFilterResetClick}
              venueInfo={venueInfo}
              eventDefaultValue={eventDefaultValue}
              summary={summary}
              photoState={photoState}
              componentName="toppicks"
            />
          </div>
        </Col>
      </Row>
    );
  }
}

TabOne.propTypes = {
  favoritePhotos: PropTypes.arrayOf(PropTypes.shape({})),
  getFavoritePhotos: PropTypes.func,
  filtersApplied: PropTypes.bool,
  removeFavoritePhotos: PropTypes.func,
  removeTopPicksFavoritePhotos: PropTypes.func,
  removeFav: PropTypes.bool,
  fetchMore: PropTypes.bool,
  nextToken: PropTypes.number,
  updatePhotos: PropTypes.bool,
  contentMsg: PropTypes.string,
  message: PropTypes.string,
};

export default TabOne;
