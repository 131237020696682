/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Button, Row, Col, Tag, Select } from 'antd';

import '../../routes/myContent/contentManagement/styles.less';

const { Option } = Select;

const Filters = ({
  momentTags,
  onTagClose,
  onMomentGoalChange,
  onMomentPenaltyChange,
  onMomentHalfTimeChange,
  onMomentRedCardChange,
  onMomentYellowCardChange,
  onMomentFullTimeChange,
  onMomentOtherChange,
  onApplyFilterClick,
  row,
  seat,
  stand,
  block,
  onStandChange,
  filterSeatDetails,
  onBlockChange,
  onSeatChange,
  onRowChange,
  onEventChange,
  disableBlock,
  disableRow,
  disableSeat,
  blockValue,
  rowValue,
  seatValue,
  onBlur,
  goalChecked,
  penChecked,
  redcardChecked,
  yellowCardChecked,
  fullTimeChecked,
  halfTimeChecked,
  otherChecked,
  standValue,
  onFilterResetClick,
  venueInfo,
  eventDefaultValue,
  eventSelectRef,
  summary: { number_moments = [] },
  photoState,
  componentName,
}) => {
  let dictionary = {};
  photoState.length > 0 &&
    number_moments.forEach(value => {
      dictionary = { ...dictionary, ...value };
    });

  return (
    <>
      <div className="filterWrapper">
        <h1 className="gx-entry-title filterHeader" style={{ fontSize: '18px', fontWeight: '500' }}>
          Active Filters
        </h1>
        {momentTags.length ? (
          momentTags.map((tags, index) => {
            return (
              <Tag
                closable
                className="tagSpan"
                key={tags}
                onClose={() => onTagClose(tags)}
                id={`${componentName}_tag_icon`}
              >
                {tags}
              </Tag>
            );
          })
        ) : (
          <p className="filterHeader">No Active Filters</p>
        )}
      </div>
      <div>
        <h1
          className="gx-entry-title filterHeader"
          style={{ fontSize: '18px', fontWeight: '500', marginTop: '8px' }}
        >
          Apply Filters
        </h1>

        <div className="filterContentContainer">
          <h4 className="filterSubHeader">Events</h4>
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: 0 }}>
              <Select
                className="filterSelect"
                mode="multiple"
                placeholder="Please select event"
                defaultValue={eventDefaultValue}
                getPopupContainer={trigger => trigger.parentNode}
                onChange={onEventChange}
                ref={eventSelectRef}
                id={`${componentName}_event_dropdown`}
              >
                {venueInfo.map(venue => (
                  <Option key={`${venue.event_id}~`}>
                    {venue.team_home} v {venue.team_away}
                    <h6>{venue.event_day}</h6>
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>

        <div className="filterContentContainer">
          <h4 className="filterSubHeader">Seat Details</h4>
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: 0 }}>
              <Select
                className="filterSelect"
                value={standValue}
                getPopupContainer={trigger => trigger.parentNode}
                onChange={onStandChange}
                id={`${componentName}_stand_dropdown`}
              >
                {stand.map(item => (
                  <Option key={item.label}>{item.value}</Option>
                ))}
                <Option key="North">North</Option>
              </Select>
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: 0 }}>
              <Select
                className="filterSelect customSelectMargin"
                getPopupContainer={trigger => trigger.parentNode}
                onChange={onBlockChange}
                disabled={disableBlock}
                value={blockValue}
                id={`${componentName}_block_dropdown`}
              >
                {block.map(item => (
                  <Option key={item.label}>{item.value}</Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={24} lg={12} xl={12} className="rowFilterCol">
              <Select
                className="filterSelect customSelectMargin"
                defaultValue="Row"
                getPopupContainer={trigger => trigger.parentNode}
                onChange={onRowChange}
                disabled={disableRow}
                value={rowValue}
                id={`${componentName}_row_dropdown`}
              >
                {row.map(item => (
                  <Option key={item.label}>{item.value}</Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} className="seatFilterCol">
              <Select
                className="filterSelect customSelectMargin"
                getPopupContainer={trigger => trigger.parentNode}
                onChange={onSeatChange}
                disabled={disableSeat}
                value={seatValue}
                id={`${componentName}_seat_dropdown`}
              >
                {seat.map(item => (
                  <Option key={item.label}>{item.value}</Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>

        <div className="filterContentContainer" style={{ whiteSpace: 'nowrap' }}>
          <h4 className="filterSubHeader mtHeader">Moment</h4>
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={14} lg={24} xl={12} style={{ padding: '0px' }}>
              <Checkbox
                onChange={onMomentGoalChange}
                className="menuCheckBox flagPhotoCheckBox"
                checked={goalChecked}
                id={`${componentName}_goal_checkbox`}
              >
                <span className="menuItemFont">
                  Goal <span className="summaryMomentText">({dictionary['goal'] || '_'})</span>
                </span>
              </Checkbox>
            </Col>
            <Col xs={24} sm={24} md={14} lg={24} xl={12} style={{ padding: '0px' }}>
              <Checkbox
                onChange={onMomentPenaltyChange}
                className="menuCheckBox flagPhotoCheckBox"
                checked={penChecked}
                id={`${componentName}_penalty_checkbox`}
              >
                <span className="menuItemFont">
                  Penalty <span className="summaryMomentText">({dictionary['pen'] || '_'})</span>
                </span>
              </Checkbox>
            </Col>
          </Row>

          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={14} lg={24} xl={12} style={{ padding: '0px' }}>
              <Checkbox
                onChange={onMomentRedCardChange}
                checked={redcardChecked}
                className="menuCheckBox flagPhotoCheckBox"
                id={`${componentName}_redcard_checkbox`}
              >
                <span className="menuItemFont">
                  Red Card{' '}
                  <span className="summaryMomentText">({dictionary['redcard'] || '_'})</span>
                </span>
              </Checkbox>
            </Col>
            <Col xs={24} sm={24} md={14} lg={24} xl={12} style={{ padding: '0px' }}>
              <Checkbox
                onChange={onMomentYellowCardChange}
                className="menuCheckBox flagPhotoCheckBox"
                checked={yellowCardChecked}
                id={`${componentName}_yellowcard_checkbox`}
              >
                <span className="menuItemFont">
                  Yellow Card{' '}
                  <span className="summaryMomentText">({dictionary['yellowcard'] || '_'})</span>
                </span>
              </Checkbox>
            </Col>
          </Row>

          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={14} lg={24} xl={12} style={{ padding: '0px' }}>
              <Checkbox
                onChange={onMomentFullTimeChange}
                className="menuCheckBox flagPhotoCheckBox"
                checked={fullTimeChecked}
                id={`${componentName}_fulltime_checkbox`}
              >
                <span className="menuItemFont">
                  Full Time <span className="summaryMomentText">({dictionary['ft'] || '_'})</span>
                </span>
              </Checkbox>
            </Col>
            <Col xs={24} sm={24} md={14} lg={24} xl={12} style={{ padding: '0px' }}>
              <Checkbox
                onChange={onMomentHalfTimeChange}
                className="menuCheckBox flagPhotoCheckBox"
                checked={halfTimeChecked}
                id={`${componentName}_halftime_checkbox`}
              >
                <span className="menuItemFont">
                  Half Time <span className="summaryMomentText">({dictionary['ht'] || '_'})</span>
                </span>
              </Checkbox>
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col xs={24} sm={24} md={14} lg={24} xl={12} style={{ padding: '0px' }}>
              <Checkbox
                onChange={onMomentOtherChange}
                className="menuCheckBox flagPhotoCheckBox"
                checked={otherChecked}
                id={`${componentName}_other_checkbox`}
              >
                <span className="menuItemFont">
                  Other <span className="summaryMomentText">({dictionary['other'] || '_'})</span>
                </span>
              </Checkbox>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <Row type="flex" style={{ justifyContent: 'space-around', marginTop: '5%' }}>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ padding: '0px' }}>
            <Button onClick={onFilterResetClick} id={`${componentName}_filter_reset_button`}>
              Reset
            </Button>
          </Col>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ padding: '0px' }}>
            <Button
              className="filterButton"
              onClick={onApplyFilterClick}
              id={`${componentName}_filter_apply_button`}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

Filters.propTypes = {
  momentTags: PropTypes.any,
  onTagClose: PropTypes.func,
  onMomentGoalChange: PropTypes.func,
  onMomentPenaltyChange: PropTypes.func,
  onMomentHalfTimeChange: PropTypes.func,
  onMomentRedCardChange: PropTypes.func,
  onMomentYellowCardChange: PropTypes.func,
  onMomentFullTimeChange: PropTypes.func,
  onMomentOtherChange: PropTypes.func,
  onApplyFilterClick: PropTypes.func,
};

export default React.memo(Filters);
