/* eslint-disable */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Card, Icon, Row, Col, Button, Divider } from 'antd';
import uniqWith from 'lodash/uniqWith';
import Modal from 'react-responsive-modal';
import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import TextareaAutosize from 'react-textarea-autosize';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Cropper from 'react-easy-crop';
import { CropperWrapper, StyledModal, StyledFooterButton } from './styles.js';

import './styles.less';
import Starred from '../Starred/Starred';
import getCroppedImg from './cropImage';

const Imagesvg = () => {
  return (
    <div style={{ background: '#CCC' }}>
      <svg viewBox="0 0 100 100">
        <rect width="100" height="100" rx="0" ry="0" fill="#CCC" />
      </svg>
    </div>
  );
};

const ModalImagesvg = () => {
  return (
    <svg width="156" height="152" viewBox="0 0 100 100" style={{ borderRadius: 13 }}>
      <rect width="100" height="100" rx="10" ry="10" fill="#CCC" />
    </svg>
  );
};

const arr = [1, 2, 3, 4, 5, 6];

const modalStyle = {
  modal: {
    backgroundColor: '#f3f3f3',
    padding: '11px',
    width: '800px',
  },
  closeButton: {
    cursor: 'pointer',
  },
  closeIcon: {
    fill: '#C8C8C8',
    width: '22px',
  },
  transitionExit: {
    opacity: '1',
  },
};

class Thumbnails extends Component {
  constructor(props) {
    super(props);
    this.textAreaRef = createRef();
  }
  state = {
    loaded: null,
    selectPhoto: null,
    unselectCheckedPhoto: true,
    countPhoto: 1,
    open: false,
    modalImage: '',
    facebookIconClick: false,
    instagramIconClick: false,
    twitterIconClick: false,
    whatsAppIconClick: false,
    crop: { x: 0, y: 0 },
    zoom: 1,
    croppedAreaPixels: null,
    croppedImage: null,
    shareUrl: '',
    soacialShareUrl: '',
    galleryImageState: '',
    slidesToShow: '',
    overlays: [],
    updateOverlay: false,
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels,
    });
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  onZoomChange = zoom => {
    this.setState({ zoom });
  };

  rendered = () => {
    this.setState({
      loaded: true,
    });
  };

  startRender = () => {
    requestAnimationFrame(this.rendered);
  };

  showImage = () => {
    requestAnimationFrame(this.startRender);
  };

  onClickPhoto = () => {
    const { actionCheckBoxChecked, unSelectPhoto, src } = this.props;

    if (!actionCheckBoxChecked) {
      this.setState(prevState => ({
        selectPhoto: !prevState.selectPhoto,
      }));

      this.setState(prevState => {
        if (!prevState.selectPhoto) {
          unSelectPhoto({ photo_url: src });
        }
      });
    }

    if (actionCheckBoxChecked) {
      this.setState(prevState => ({
        unselectCheckedPhoto: !prevState.unselectCheckedPhoto,
      }));
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { onSelectPhoto, src, unSelectPhoto } = this.props;

    if (
      prevState.selectPhoto !== this.state.selectPhoto &&
      this.state.selectPhoto &&
      !this.props.actionCheckBoxChecked
    ) {
      onSelectPhoto({ photo_url: src });
    }
    if (!this.props.actionCheckBoxChecked && !this.state.unselectCheckedPhoto) {
      this.setState(prevState => ({
        unselectCheckedPhoto: !prevState.unselectCheckedPhoto,
      }));
    }
    if (
      prevProps.actionCheckBoxChecked !== this.props.actionCheckBoxChecked &&
      this.props.actionCheckBoxChecked &&
      !this.state.unselectCheckedPhoto
    ) {
      unSelectPhoto({ photo_url: src });
    }

    if (this.state.selectPhoto && this.props.actionCheckBoxChecked) {
      this.setState(prevState => ({
        selectPhoto: !prevState.selectPhoto,
      }));
    }
  }

  onOpenModal = (event, image) => {
    event.stopPropagation();
    this.props.getOverlay({
      data: {
        venueID: 'jca',
        eventID: this.props.event_id,
      },
    });

    this.setState({
      open: true,
      modalImage: image,
    });
  };

  onCloseModal = () => {
    this.setState({
      open: false,
      updateOverlay: false,
    });
  };

  onClickDownload = async () => {
    const imageOverlay =
      this.state.galleryImageState === '' ? this.props.overlay : this.state.galleryImageState;
    const croppedImage = await getCroppedImg(
      this.props.photo,
      imageOverlay,
      this.state.croppedAreaPixels,
      0,
    );

    const newImage = fetch(croppedImage).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'image.png';
        a.click();
      });
    });
  };

  onClickFacebookIcon = e => {
    e.stopPropagation();
    const { shareUrl } = this.state;
    const { src } = this.props;
    const url = shareUrl || src;

    this.setState(prevState => ({
      facebookIconClick: !prevState.facebookIconClick,
      soacialShareUrl: `https://www.facebook.com/sharer.php?u=${url}`,
    }));
  };

  onClickInstagramIcon = e => {
    e.stopPropagation();

    this.setState(prevState => ({
      instagramIconClick: !prevState.instagramIconClick,
    }));
  };

  onClickTwitterIcon = e => {
    e.stopPropagation();
    const { shareUrl } = this.state;
    const { src } = this.props;
    const url = shareUrl || src;

    this.setState(prevState => ({
      twitterIconClick: !prevState.twitterIconClick,
      soacialShareUrl: `https://twitter.com/intent/tweet?url=${url}`,
    }));
  };

  onClickWhatsappIcon = e => {
    e.stopPropagation();
    const { shareUrl } = this.state;
    const { src } = this.props;
    const url = shareUrl || src;

    this.setState(prevState => ({
      whatsAppIconClick: !prevState.whatsAppIconClick,
      soacialShareUrl: `https://wa.me/?text=${url}`,
    }));
  };

  onResetTexArea = () => {
    document.getElementById('textAreaId').value = '';
  };

  onClickReset = e => {
    e.preventDefault();
    this.setState({
      facebookIconClick: false,
      instagramIconClick: false,
      twitterIconClick: false,
      whatsAppIconClick: false,
      zoom: 1,
      shareUrl: '',
      soacialShareUrl: '',
      galleryImageState: '',
    });

    this.onResetTexArea();
  };

  onClickSliderImage = overlay => {
    this.setState({
      galleryImageState: overlay,
    });
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const {
      src,
      showIcon,
      onClick,
      showCursorPointer,
      height,
      width,
      showStarForAllPhotos,
      createFavoritePhotos,
      enablePhotoSelect,
      isFavourite,
      removeFavoritePhotos,
      actionCheckBoxChecked,
      removeTopPicksFavoritePhotos,
      photoState = [],
      seat_id,
      photo,
      overlay,
      postMyAsset,
      overlays,
      getFavoritePhotos,
      eventID,
      moments,
      seatID,
      campaignFilter,
      componentName,
    } = this.props;

    const {
      selectPhoto,
      unselectCheckedPhoto,
      loaded,
      open,
      modalImage,
      facebookIconClick,
      instagramIconClick,
      twitterIconClick,
      whatsAppIconClick,
      soacialShareUrl,
      galleryImageState,
      galleryImageloaded,
    } = this.state;

    let thumbnailImageClass;
    if (showCursorPointer) {
      thumbnailImageClass = 'thumbnailImageWithCursorPointer';
    } else if (!showCursorPointer && !selectPhoto) {
      thumbnailImageClass = 'thumbnailImage';
    } else if (selectPhoto && !actionCheckBoxChecked) {
      thumbnailImageClass = 'selectedImage';
    }

    if (actionCheckBoxChecked) {
      thumbnailImageClass = 'selectedImage';
    }
    if (!unselectCheckedPhoto) {
      thumbnailImageClass = 'thumbnailImage';
    }

    let selectFaceBookIconClass;
    let selectInstagramIconClass;
    let selectTwitterIconClass;
    let selectWhatsappIconClass;

    if (facebookIconClick) {
      selectFaceBookIconClass = 'modalSelectState';
    }
    if (instagramIconClick) {
      selectInstagramIconClass = 'modalSelectInstagramState';
    } else {
      selectInstagramIconClass = 'instagramIcon';
    }
    if (twitterIconClick) {
      selectTwitterIconClass = 'modalSelectState';
    }

    if (whatsAppIconClick) {
      selectWhatsappIconClass = 'modalSelectState';
    }

    let setSlides = '';
    let showOtherSection;
    switch (true) {
      case window.innerWidth <= 399:
        setSlides = 2;
        break;
      case window.innerWidth >= 400 && window.innerWidth <= 599:
        setSlides = 3;
        break;
      case window.innerWidth >= 600 && window.innerWidth <= 800:
        setSlides = 4;
        break;
      case window.innerWidth >= 801:
        setSlides = 5;
        break;
      default:
        setSlides = 5;
        showOtherSection = true;
        break;
    }

    if (window.innerWidth >= 490 && window.innerWidth <= 767) {
      showOtherSection = false;
    } else {
      showOtherSection = true;
    }

    const settings = {
      slidesToShow: setSlides,
      slidesToScroll: 1,
      touchMove: false,
      focusOnSelect: false,
    };

    const imageOverlay =
      this.state.galleryImageState === '' ? this.props.overlay : this.state.galleryImageState;

    return (
      <>
        <div style={{ position: 'relative' }}>
          <Card
            hoverable
            style={{ marginTop: 6, marginBottom: 20 }}
            className="imageCard"
            cover={
              <>
                <div className="imageInfoIcon">
                  <Tooltip
                    id={`${componentName}_tooltip`}
                    trigger={['click']}
                    placement="leftTop"
                    title={seat_id}
                    getPopupContainer={trigger => trigger.parentNode}
                  >
                    <Button
                      shape="circle"
                      icon="info"
                      id={`${componentName}_info_button`}
                      size="small"
                    />
                  </Tooltip>
                </div>
                <div>
                  {showIcon && loaded && (
                    <Starred
                      top="102px"
                      removeTopPicksFavoritePhotos={removeTopPicksFavoritePhotos}
                      src={src}
                      topPicks={true}
                      photoState={photoState}
                      getFavoritePhotos={getFavoritePhotos}
                      eventID={eventID}
                      moments={moments}
                      seatID={seatID}
                    />
                  )}
                  {!showIcon && loaded && (
                    <Starred
                      createFavoritePhotos={createFavoritePhotos}
                      src={src}
                      isFavourite={isFavourite}
                      removeFavoritePhotos={removeFavoritePhotos}
                      top="80px"
                      topPicks={false}
                      getFavoritePhotos={getFavoritePhotos}
                      eventID={eventID}
                      moments={moments}
                      seatID={seatID}
                      campaignFilter={campaignFilter}
                    />
                  )}
                  {loaded && (
                    <Button
                      shape="circle"
                      icon="search"
                      onClick={e => this.onOpenModal(e, src)}
                      className="imageSearchIcon"
                      id={`${componentName}_expand_button`}
                      size="small"
                    />
                  )}
                </div>
                {!loaded && <Imagesvg />}
                <img
                  src={src}
                  onLoad={this.showImage}
                  style={loaded ? {} : { display: 'none' }}
                  className={thumbnailImageClass}
                  height={height}
                  width={width}
                  onClick={enablePhotoSelect ? this.onClickPhoto : onClick}
                />
              </>
            }
          ></Card>
          <div className="modalContent">
            <Modal open={open} onClose={this.onCloseModal} styles={modalStyle} id="testModal">
              <Row type="flex" justify="start">
                <Col xs={20} sm={18} md={14} lg={14} xl={12} xxl={12} style={{ padding: 0 }}>
                  <div className="thumnailImageWrapper">
                    <>
                      <Card
                        className="modalImageCard"
                        id={`${componentName}_card_cropper`}
                        cover={
                          <CropperWrapper
                            id={`${componentName}_cropper`}
                            className="imageWidthForSmallScreen"
                          >
                            <Cropper
                              image={this.props.photo}
                              crop={this.state.crop}
                              zoom={this.state.zoom}
                              aspect={1}
                              showGrid={false}
                              onCropChange={this.onCropChange}
                              onCropComplete={this.onCropComplete}
                              onZoomChange={this.onZoomChange}
                              style={{
                                cropAreaStyle: {
                                  backgroundImage: `url('${imageOverlay}')`,
                                  backgroundSize: '100% 400px',
                                  border: 'none',
                                },
                              }}
                            />
                          </CropperWrapper>
                        }
                      ></Card>
                    </>
                  </div>
                </Col>
                <Col
                  xs={showOtherSection === false ? 4 : 24}
                  sm={6}
                  md={10}
                  lg={10}
                  xl={12}
                  style={{}}
                  className="colTest"
                >
                  <Row type="flex" justify="start" style={{ marginBottom: '5%' }}>
                    <Col xs={showOtherSection === false ? 24 : 6} sm={1} md={6} lg={6} xl={6}>
                      <div className="sharePhotoModalIcon">
                        <FacebookShareButton id={`${componentName}_facebook_icon`}>
                          <FacebookIcon
                            size={42}
                            round
                            style={{ marginBottom: 10 }}
                            className={`modalIcons ${selectFaceBookIconClass}`}
                            onClick={e => this.onClickFacebookIcon(e)}
                          />
                        </FacebookShareButton>
                        <Button
                          shape="circle"
                          id={`${componentName}_instagram_icon`}
                          className={`modalIcons ${selectInstagramIconClass}`}
                          icon="instagram"
                          type="link"
                          onClick={e => this.onClickInstagramIcon(e)}
                          id={`${componentName}_download_icon`}
                        />
                        <TwitterShareButton id={`${componentName}_twitter_icon`}>
                          <TwitterIcon
                            size={42}
                            round
                            style={{ marginBottom: 10 }}
                            className={`modalIcons ${selectTwitterIconClass}`}
                            onClick={e => this.onClickTwitterIcon(e)}
                          />
                        </TwitterShareButton>

                        <WhatsappShareButton id={`${componentName}_whatsapp_icon`}>
                          <WhatsappIcon
                            size={42}
                            round
                            style={{ marginBottom: 10 }}
                            onClick={e => this.onClickWhatsappIcon(e)}
                            className={`modalIcons ${selectWhatsappIconClass}`}
                          />
                        </WhatsappShareButton>

                        <Button
                          shape="circle"
                          className="modalIcons"
                          icon="download"
                          type="link"
                          onClick={this.onClickDownload}
                          style={{ color: 'dimgrey', fontSize: 38 }}
                          id={`${componentName}_download_icon`}
                        />
                      </div>
                    </Col>

                    {showOtherSection && (
                      <Col xs={18} sm={12} md={16} lg={16} xl={16}>
                        <TextareaAutosize
                          className="modalTexArea"
                          id="textAreaId"
                          placeholder="Type message here....."
                          minRows={5}
                          maxRows={7}
                          inputRef={this.textAreaRef}
                        />
                      </Col>
                    )}
                  </Row>
                  {showOtherSection && (
                    <Row type="flex" justify="start" className="modalFooterBtn">
                      <Col xs={17} sm={8} md={12} lg={12} xl={12}>
                        <Button
                          type="primary"
                          onClick={this.onClickReset}
                          id={`${componentName}_single_image_reset_button`}
                        >
                          Reset
                        </Button>
                      </Col>
                      <Col xs={5} sm={16} md={12} lg={12} xl={12}>
                        <Button type="primary" id={`${componentName}_publish_button`}>
                          {/* <a href={soacialShareUrl} target="blank" rel="noopener noreferrer"> */}
                          Publish
                          {/* </a> */}
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>

                {showOtherSection === false && (
                  <Col xs={24} sm={24} md={10} lg={14} xl={12} style={{}} className="colTest">
                    <Row type="flex" justify="start" style={{ marginBottom: '5%' }}>
                      <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                        <TextareaAutosize
                          className="modalTexArea"
                          id="textAreaId"
                          placeholder="Type message here....."
                          minRows={5}
                          maxRows={7}
                          inputRef={this.textAreaRef}
                        />
                      </Col>
                    </Row>

                    <Row type="flex" justify="space-around" className="modalFooterBtn">
                      <Col xs={18} sm={20} md={12} lg={12} xl={12}>
                        <Button
                          type="primary"
                          onClick={this.onClickReset}
                          id={`${componentName}_single_image_reset_button`}
                        >
                          Reset
                        </Button>
                      </Col>
                      <Col xs={5} sm={4} md={12} lg={12} xl={12}>
                        <Button type="primary" id={`${componentName}_publish_button`}>
                          {/* <a href={soacialShareUrl} target="blank" rel="noopener noreferrer"> */}
                          Publish
                          {/* </a> */}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              <Divider style={{ margin: 1, background: 'white', width: '101%' }} />
              <div>
                <Slider {...settings}>
                  {overlays.map(item => {
                    return (
                      <Card
                        hoverable
                        key={item}
                        style={{
                          borderRadius: 10,
                          border: '1px solid white',
                        }}
                        className="galleryCard"
                        cover={
                          <>
                            <img src={item} onClick={() => this.onClickSliderImage(item)} />
                          </>
                        }
                      ></Card>
                    );
                  })}
                </Slider>
              </div>
            </Modal>
          </div>
        </div>
      </>
    );
  }
}

Thumbnails.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.string,
  theme: PropTypes.string.isRequired,
  showIcon: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  showCursorPointer: PropTypes.bool,
  showStarForAllPhotos: PropTypes.bool,
  starred: PropTypes.bool,
  actionCheckBoxChecked: PropTypes.bool,
  unSelectPhoto: PropTypes.func,
  createFavoritePhotos: PropTypes.func,
  enablePhotoSelect: PropTypes.bool,
  isFavourite: PropTypes.bool,
  removeFavoritePhotos: PropTypes.func,
  onSelectPhoto: PropTypes.func,
  removeTopPicksFavoritePhotos: PropTypes.func,
};

export default Thumbnails;
