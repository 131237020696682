import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CreateCampaign from './CreateCampaign';
import FanProfiling from './FanProfiling';
import ManageCampaignContainer from './ManageCampaign/ManageCampaignContainer.js';
import CampaignManagementContainer from './CampaignManagement/CampaignManagementContainer';

import './styles.less';

const MyCampaigns = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/mycampaigns`} />
    <Route path={`${match.url}/mycampaigns`} component={ManageCampaignContainer} />
    <Route path={`${match.url}/createcampaign`} component={CreateCampaign} />
    <Route path={`${match.url}/fanprofiling`} component={FanProfiling} />
    <Route path={`${match.url}/managecampaign/:eventid`} component={CampaignManagementContainer} />
  </Switch>
);

export default MyCampaigns;
