import { all, fork } from 'redux-saga/effects';
import {
  getPhotosSaga,
  getFavoritePhotosSaga,
  createFavoritePhotoSaga,
  flagPhotoSaga,
  removeFavoritePhotoSaga,
  selectPhotoSaga,
  unSelectPhotoSaga,
  selectAllPhotosSaga,
  unSelectAllPhotosSaga,
  removeTopPicksFavoritePhotoSaga,
  getSeatMapSaga,
  getUserFavouriteAssestsFromVenueSaga,
  postMyAssetSaga,
  getOverlaySaga,
} from './features/photos/photosSagas';

import authSagas from './features/auth/authSagas';
import campaignSagas from './features/campaign/campaignSagas';

export default function* rootSaga() {
  yield all([
    fork(getPhotosSaga),
    fork(getFavoritePhotosSaga),
    fork(createFavoritePhotoSaga),
    fork(flagPhotoSaga),
    fork(removeFavoritePhotoSaga),
    fork(selectPhotoSaga),
    fork(unSelectPhotoSaga),
    fork(unSelectAllPhotosSaga),
    fork(selectAllPhotosSaga),
    fork(removeTopPicksFavoritePhotoSaga),
    fork(getSeatMapSaga),
    fork(getUserFavouriteAssestsFromVenueSaga),
    fork(postMyAssetSaga),
    fork(getOverlaySaga),
    authSagas(),
    campaignSagas(),
  ]);
}
