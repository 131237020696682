import { connect } from 'react-redux';
import ContentManagement from './ContentManagement';
import {
  getAllPhotos,
  getFavoritePhotos,
  createFavoritePhotos,
  flagPhoto,
  selectPhoto,
  removeFavoritePhotos,
  unSelectPhoto,
  selectAllPhotos,
  unSelectAllPhotos,
  removeTopPicksFavoritePhotos,
  getSeatMap,
  getUserFavouriteAssestsFromVenue,
  postMyAsset,
  getOverlay,
} from '../../../features/photos/photosActions';

import { removeCampaignFilter } from '../../../features/campaign/campaignActions';
import { signout } from '../../../features/auth/authActions';

const mapStateToProps = state => ({
  allPhotos: state.assets.allPhotos.photos,
  assetsSummary: state.assets.assetsSummary,
  nextToken: state.assets.allPhotos.nextToken,
  favNextToken: state.assets.favoritePhotos.nextToken,
  filtersApplied: state.assets.allPhotos.filtersApplied,
  favoritePhotos: state.assets.favoritePhotos.photos,
  favoriteFiltersApplied: state.assets.favoritePhotos.filtersApplied,
  selectedPhotos: state.assets.selectedPhotos.photos,
  removeFav: state.assets.favoritePhotos.removeFav,
  fetchMore: state.assets.allPhotos.fetchMore,
  fetchMoreFavoritePhotos: state.assets.favoritePhotos.fetchMore,
  resetMoment: state.assets.favoritePhotos.resetMoment,
  updatePhotos: state.assets.allPhotos.updatePhotos,
  updateFavPhotos: state.assets.favoritePhotos.updatePhotos,
  message: state.assets.allPhotos.message,
  favMessage: state.assets.favoritePhotos.message,
  contentMsg: state.assets.allPhotos.contentMsg,
  favContentMsg: state.assets.favoritePhotos.contentMsg,
  seatMapList: state.assets.seatMap.seatMapList,
  venueInfo: state.assets.venues.venue_info,
  favSummary: state.assets.favSummary,
  overlays: state.assets.overlayAsset.overlays,
  campaignFilter: state.campaignReducer.campaignFilter,
});

const mapDispatchToProps = dispatch => ({
  getAllPhotos: payload => dispatch(getAllPhotos(payload)),
  getFavoritePhotos: payload => dispatch(getFavoritePhotos(payload)),
  createFavoritePhotos: payload => dispatch(createFavoritePhotos(payload)),
  flagPhoto: payload => dispatch(flagPhoto(payload)),
  onSelectPhoto: payload => dispatch(selectPhoto(payload)),
  removeFavoritePhotos: payload => dispatch(removeFavoritePhotos(payload)),
  unSelectPhoto: payload => dispatch(unSelectPhoto(payload)),
  selectAllPhotos: payload => dispatch(selectAllPhotos(payload)),
  unSelectAllPhotos: () => dispatch(unSelectAllPhotos()),
  removeTopPicksFavoritePhotos: payload => dispatch(removeTopPicksFavoritePhotos(payload)),
  getSeatMap: () => dispatch(getSeatMap()),
  getUserFavouriteAssestsFromVenue: () => dispatch(getUserFavouriteAssestsFromVenue()),
  postMyAsset: payload => dispatch(postMyAsset(payload)),
  getOverlay: payload => dispatch(getOverlay(payload)),
  removeCampaignFilter: () => dispatch(removeCampaignFilter()),
  signout: () => dispatch(signout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentManagement);
