import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ContentManagementContainer from './contentManagement/ContentManagementContainer';

const MyContent = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/contentmanagement`} component={ContentManagementContainer} />
  </Switch>
);

export default MyContent;
