/* eslint-disable */
import React, { useEffect, useMemo, Suspense } from 'react';
import PropTypes from 'prop-types';
import uniqWith from 'lodash/uniqWith';
import { Tabs } from 'antd';
import './styles.less';
import TabOneContent from './tabSections/TabOneContent';
import TabTwoContent from './tabSections/TabTwoSection';
import { useDispatch, useSelector } from 'react-redux';

const { TabPane } = Tabs;

const ContentManagement = ({
  getAllPhotos,
  allPhotos,
  message,
  getFavoritePhotos,
  favoritePhotos,
  createFavoritePhotos,
  flagPhoto,
  removeFavoritePhotos,
  onSelectPhoto,
  selectedPhotos,
  unSelectPhoto,
  selectAllPhotos,
  unSelectAllPhotos,
  filtersApplied,
  favoriteFiltersApplied,
  favNextToken,
  removeTopPicksFavoritePhotos,
  removeFav,
  fetchMore,
  updatePhotos,
  fetchMoreFavoritePhotos,
  favMessage,
  nextToken,
  contentMsg,
  updateFavPhotos,
  favContentMsg,
  getSeatMap,
  seatMapList,
  getUserFavouriteAssestsFromVenue,
  venueInfo,
  postMyAsset,
  assetsSummary,
  favSummary,
  getOverlay,
  overlays,
  campaignFilter,
  removeCampaignFilter,
  signout,
  resetMoment,
}) => {
  const token = useSelector(({ authReducer }) => authReducer.token);
  const dispatch = useDispatch();
  useEffect(() => {
    getSeatMap();
    getUserFavouriteAssestsFromVenue();
    // Handle for this reloading
  }, [getSeatMap, getUserFavouriteAssestsFromVenue]);

  // Revisit Logic in here as values for dropdown maybe incorrect "Get Dropdown values with property keys"
  const getStand = useMemo(() => {
    const defaultArray = [];
    if (seatMapList && seatMapList.length) {
      return seatMapList.map(list => {
        return { label: list.stand_id, value: list.stand_id };
      });
    }

    return defaultArray;
  }, [seatMapList]);

  const getBlock = useMemo(() => {
    const defaultArray = [];
    if (seatMapList && seatMapList.length) {
      return seatMapList.map(list => {
        return list.block;
      });
    }

    return defaultArray;
  }, [seatMapList]);

  const getBlockID = useMemo(() => {
    const storeArray = [];
    getBlock.map(block => {
      block.forEach(items => {
        storeArray.push({ label: items.block_id, value: items.block_id });
      });
    });
    return storeArray;
  }, [getBlock]);

  const getBlockRow = useMemo(() => {
    const storeArray = [];
    getBlock.map(block => {
      block.forEach(obj => {
        storeArray.push(obj.row);
      });
    });
    return storeArray;
  }, [getBlock]);

  const getBlockRowID = useMemo(() => {
    const storeArray = [];
    // Reafactor Code
    getBlockRow.forEach(rowObj => {
      rowObj.forEach(row => {
        storeArray.push({ value: row.row_id, label: row.row_id });
      });
    });
    return uniqWith(storeArray, (a, b) => a.label === b.label);
  }, [getBlockRow]);

  const getSeatObj = useMemo(() => {
    const storeArray = [];
    // Reafactor Code
    getBlockRow.forEach(obj => {
      obj.forEach(item => {
        item.seat.forEach(seatObj => {
          storeArray.push({ value: seatObj.seat_id, label: seatObj.seat_id });
        });
      });
    });

    return uniqWith(storeArray, (a, b) => a.label === b.label);
  }, [getBlockRow]);

  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <h2 className="tabsHeader">
              All Photos
              {assetsSummary.summary && (
                <span className="summaryText">
                  {' '}
                  ({assetsSummary.summary.number_photos || `__`})
                </span>
              )}
            </h2>
          }
          key="1"
        >
          <TabTwoContent
            allPhotos={allPhotos}
            message={message}
            getAllPhotos={getAllPhotos}
            createFavoritePhotos={createFavoritePhotos}
            flagPhoto={flagPhoto}
            removeFavoritePhotos={removeFavoritePhotos}
            onSelectPhoto={onSelectPhoto}
            selectedPhotos={selectedPhotos}
            unSelectPhoto={unSelectPhoto}
            selectAllPhotos={selectAllPhotos}
            unSelectAllPhotos={unSelectAllPhotos}
            filtersApplied={filtersApplied}
            nextToken={nextToken}
            fetchMore={fetchMore}
            updatePhotos={updatePhotos}
            contentMsg={contentMsg}
            seat={getSeatObj}
            row={getBlockRowID}
            block={getBlockID}
            stand={getStand}
            venueInfo={venueInfo}
            postMyAsset={postMyAsset}
            summary={assetsSummary.summary}
            getOverlay={getOverlay}
            overlays={overlays}
            campaignFilter={campaignFilter}
            removeCampaignFilter={removeCampaignFilter}
            token={token}
            getFavoritePhotos={getFavoritePhotos}
          />
        </TabPane>
        <TabPane
          tab={
            <h2 className="tabsHeader">
              Top Picks
              {favSummary.summary && (
                <span className="summaryText">({favSummary.summary.number_photos || `__`}) </span>
              )}
            </h2>
          }
          key="2"
        >
          <TabOneContent
            favoritePhotos={favoritePhotos}
            message={favMessage}
            nextToken={favNextToken}
            removeTopPicksFavoritePhotos={removeTopPicksFavoritePhotos}
            removeFav={removeFav}
            getFavoritePhotos={getFavoritePhotos}
            filtersApplied={favoriteFiltersApplied}
            fetchMore={fetchMoreFavoritePhotos}
            updatePhotos={updateFavPhotos}
            contentMsg={favContentMsg}
            seat={getSeatObj}
            row={getBlockRowID}
            block={getBlockID}
            stand={getStand}
            venueInfo={venueInfo}
            postMyAsset={postMyAsset}
            summary={favSummary.summary}
            getOverlay={getOverlay}
            overlays={overlays}
            campaignFilter={campaignFilter}
            removeCampaignFilter={removeCampaignFilter}
            token={token}
            resetMoment={resetMoment}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

ContentManagement.defaultProps = {
  allPhotos: [],
  favoritePhotos: [],
  selectedPhotos: [],
  message: '',
};

ContentManagement.propTypes = {
  allPhotos: PropTypes.arrayOf(PropTypes.shape({})),
  favoritePhotos: PropTypes.arrayOf(PropTypes.shape({})),
  message: PropTypes.string,
  favMessage: PropTypes.string,
  getAllPhotos: PropTypes.func.isRequired,
  getFavoritePhotos: PropTypes.func.isRequired,
  createFavoritePhotos: PropTypes.func.isRequired,
  flagPhoto: PropTypes.func.isRequired,
  removeFavoritePhotos: PropTypes.func.isRequired,
  onSelectPhoto: PropTypes.func.isRequired,
  selectedPhotos: PropTypes.any.isRequired,
  unSelectPhoto: PropTypes.func.isRequired,
  selectAllPhotos: PropTypes.func.isRequired,
  unSelectAllPhotos: PropTypes.func.isRequired,
  filtersApplied: PropTypes.bool,
  favNextToken: PropTypes.number,
  nextToken: PropTypes.number,
  favorite_token_next: PropTypes.number,
  favoriteFiltersApplied: PropTypes.bool,
  removeTopPicksFavoritePhotos: PropTypes.func,
  removeFav: PropTypes.bool,
  fetchMore: PropTypes.bool,
  updatePhotos: PropTypes.bool,
  fetchMoreFavoritePhotos: PropTypes.bool,
  contentMsg: PropTypes.string,
  updateFavPhotos: PropTypes.bool,
  favContentMsg: PropTypes.string,
};

export default ContentManagement;
