import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  FacebookIcon,
  MailruShareButton,
  PinterestShareButton,
  PocketShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  MailruIcon,
  PinterestIcon,
  TumblrIcon,
  ViberIcon,
  PocketIcon,
} from 'react-share';

const SocialMediaSharingButtons = ({ url, containerClass, showOtherSection }) => {
  return (
    <div className={containerClass}>
      <div>
        <FacebookShareButton url={url}>
          <FacebookIcon size={42} round />
        </FacebookShareButton>

        <FacebookMessengerShareButton url={url} separator=":: ">
          <FacebookMessengerIcon size={42} round />
        </FacebookMessengerShareButton>

        <TwitterShareButton url={url}>
          <TwitterIcon size={42} round />
        </TwitterShareButton>

        <WhatsappShareButton url={url}>
          <WhatsappIcon size={42} round />
        </WhatsappShareButton>

        <EmailShareButton url={url} separator=":: ">
          <EmailIcon size={42} round />
        </EmailShareButton>
      </div>
      {showOtherSection && (
        <div>
          <PinterestShareButton url={url} separator=":: ">
            <PinterestIcon size={42} round />
          </PinterestShareButton>

          <TumblrShareButton url={url} separator=":: ">
            <TumblrIcon size={42} round />
          </TumblrShareButton>

          <MailruShareButton url={url} separator=":: ">
            <MailruIcon size={42} round />
          </MailruShareButton>

          <ViberShareButton url={url} separator=":: ">
            <ViberIcon size={42} round />
          </ViberShareButton>

          <PocketShareButton url={url} separator=":: ">
            <PocketIcon size={42} round />
          </PocketShareButton>
        </div>
      )}
    </div>
  );
};

SocialMediaSharingButtons.propTypes = {
  url: PropTypes.string.isRequired,
  containerClass: PropTypes.string.isRequired,
  showOtherSection: PropTypes.bool,
};

export default SocialMediaSharingButtons;
