/* eslint-disable no-case-declarations */
import photosActionTypes from './constants/photosActionTypes';
import uniqWith from 'lodash/uniqWith';
import { MESSAGES } from './constants/index';

const dummyOverlays = ['', '', '', '', ''];

const initialState = {
  allPhotos: {
    photos: [],
    message: '',
    filtersApplied: false,
    removeFav: false,
    updatePhotos: false,
    contentMsg: '',
  },
  favoritePhotos: {
    photos: [],
    message: '',
    filtersApplied: false,
    removeFav: false,
    updatePhotos: false,
    contentMsg: '',
  },
  selectedPhotos: {
    photos: [],
    filtersApplied: false,
    removeFav: false,
    updatePhotos: false,
    contentMsg: '',
  },
  seatMap: {
    message: '',
    seatMapList: [],
  },
  venues: {
    message: '',
    venue_info: [],
  },
  assetsSummary: {
    message: '',
    summary: {},
  },
  favSummary: {
    message: '',
    summary: {},
  },
  overlayAsset: {
    overlays: dummyOverlays,
    message: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case photosActionTypes.GET_ALL_PHOTOS_REQUEST:
      return {
        ...state,
        allPhotos: {
          ...state.allPhotos,
          message: MESSAGES.FETCHING_PHOTOS_REQUESTED,
        },
      };
    case photosActionTypes.GET_ALL_PHOTOS_SUCCESSFUL:
      // To modify this section based off phong information
      let uniqAllPhotos;
      if (action.data.length) {
        uniqAllPhotos = uniqWith(action.data, (a, b) => a.my_photo === b.my_photo);
      }

      return {
        ...state,
        allPhotos: {
          photos: !action.data.length ? initialState.allPhotos.photos : uniqAllPhotos,
          message: MESSAGES.FETCHING_PHOTOS_SUCCESSFUL,
          filtersApplied: action.filtersApplied,
          fetchMore: action.fetchMore,
          nextToken: action.nextToken,
          updatePhotos: action.updatePhotos,
          contentMsg: !action.data.length ? 'No photos available' : '',
        },
      };
    case photosActionTypes.GET_ALL_PHOTOS_FAILED:
      return {
        ...state,
        allPhotos: {
          ...state.allPhotos,
          fetchMore: action.fetchMore,
          message: MESSAGES.FETCHING_PHOTOS_FAILED,
          filtersApplied: action.filtersApplied,
          photos: initialState.allPhotos.photos,
        },
      };

    case photosActionTypes.GET_USER_FAVOURITE_ASSETS_FROM_VENUE_REQUEST:
      return {
        ...state,
        venues: {
          ...state.venues,
          message: MESSAGES.FETCHING_VENUES_REQUESTED,
        },
      };
    case photosActionTypes.GET_USER_FAVOURITE_ASSETS_FROM_VENUE_SUCCESS:
      return {
        ...state,
        venues: {
          venue_info: action.data.data.venue_info,
          message: MESSAGES.FETCHING_VENUES_SUCCESSFUL,
        },
      };
    case photosActionTypes.GET_USER_FAVOURITE_ASSETS_FROM_VENUE_FAILED:
      return {
        ...state,
        venues: {
          ...state.venues,
          message: MESSAGES.FETCHING_VENUES_FAILED,
        },
      };

    case photosActionTypes.GET_FAVORITE_PHOTOS_REQUEST:
      return {
        ...state,
        favoritePhotos: {
          ...state.favoritePhotos,
          message: MESSAGES.FETCHING_PHOTOS_REQUESTED,
        },
      };
    case photosActionTypes.GET_FAVORITE_PHOTOS_SUCCESSFUL:
      // To modify this section based off phong information
      let uniqFavPhotos;
      if (action.data.length) {
        uniqFavPhotos = uniqWith(action.data, (a, b) => a.my_photo === b.my_photo);
      }

      return {
        ...state,
        favoritePhotos: {
          photos: !action.data.length ? initialState.allPhotos.photos : uniqFavPhotos,
          message: MESSAGES.FETCHING_PHOTOS_SUCCESSFUL,
          filtersApplied: action.filtersApplied,
          fetchMore: action.fetchMore,
          nextToken: action.nextToken,
          updatePhotos: action.updatePhotos,
          contentMsg: !action.data.length ? 'Fetching Photos Requested!' : '',
          resetMoment: action.resetMoment,
        },
      };
    case photosActionTypes.GET_FAVORITE_PHOTOS_FAILED:
      return {
        ...state,
        favoritePhotos: {
          ...state.favoritePhotos,
          message: MESSAGES.FETCHING_PHOTOS_FAILED,
          filtersApplied: action.filtersApplied,
          photos: initialState.favoritePhotos.photos,
          fetchMore: action.fetchMore,
        },
      };
    case photosActionTypes.REMOVE_TOP_PICKS_FAVORITE_PHOTO_REQUEST:
      return {
        ...state,
        favoritePhotos: {
          ...state.favoritePhotos,
          updatePhotos: true,
        },
      };
    case photosActionTypes.CREATE_FAVORITE_PHOTO_SUCCESSFUL:
      return {
        ...state,
        favoritePhotos: {
          ...state.favoritePhotos,
          updatePhotos: true,
        },
      };
    case photosActionTypes.CREATE_FAVORITE_PHOTO_REQUEST:
      return {
        ...state,
        favoritePhotos: {
          ...state.favoritePhotos,
          updatePhotos: true,
        },
      };
    case photosActionTypes.REMOVE_FAVORITE_PHOTO_REQUEST:
      return {
        ...state,
        favoritePhotos: {
          ...state.favoritePhotos,
        },
      };
    case photosActionTypes.REMOVE_FAVORITE_PHOTO_SUCCESSFUL:
      return {
        ...state,
        favoritePhotos: {
          ...state.favoritePhotos,
          updatePhotos: true,
        },
      };
    case photosActionTypes.REMOVE_TOP_PICKS_FAVORITE_PHOTO_SUCCESSFUL:
      const newFilteredPhotos = action.assets.photoState.filter(
        photo => photo.my_photo !== action.assets.asset_url,
      );

      return {
        ...state,
        favoritePhotos: {
          photos: [...newFilteredPhotos],
          removeFav: true,
          updatePhotos: true,
        },
      };
    case photosActionTypes.FLAG_PHOTO:
      let filteredNonFlagPhotos;
      if (action.payload.photoState) {
        filteredNonFlagPhotos = action.payload.photoState.filter(
          item => !action.payload.assets.includes(item.my_photo),
        );
      } else {
        filteredNonFlagPhotos = state.allPhotos.photos.filter(
          item => !action.payload.assets.includes(item.my_photo),
        );
      }

      return {
        ...state,
        allPhotos: {
          ...state.allPhotos,
          photos: [...filteredNonFlagPhotos],
          updatePhotos: true,
        },
      };
    case photosActionTypes.SELECT_PHOTO_SUCCESSFUL:
      const uniqSelectedPhotos = uniqWith(state.selectedPhotos.photos, (a, b) => a === b);
      return {
        ...state,
        selectedPhotos: {
          photos: [...uniqSelectedPhotos, action.data.photo_url],
          updatePhotos: false,
        },
      };
    case photosActionTypes.UNSELECT_PHOTO_SUCCESSFUL:
      const filteredPhotos = state.selectedPhotos.photos.filter(
        photo => photo !== action.data.photo_url,
      );
      return {
        ...state,
        selectedPhotos: {
          photos: [...filteredPhotos],
          updatePhotos: false,
        },
      };
    case photosActionTypes.SELECT_ALL_PHOTOS_SUCCESSFUL:
      if (state.selectedPhotos.photos.length === 0)
        return {
          ...state,
          selectedPhotos: {
            photos: [...action.data],
            updatePhotos: false,
          },
        };
      const removeDuplicates = action.data.filter(item => {
        return state.selectedPhotos.photos.indexOf(item) === -1;
      });
      return {
        ...state,
        selectedPhotos: {
          photos: [...removeDuplicates],
          updatePhotos: false,
        },
      };
    case photosActionTypes.UNSELECT_ALL_PHOTOS_SUCCESSFUL:
      return {
        ...state,
        selectedPhotos: {
          photos: initialState.selectedPhotos.photos,
          updatePhotos: false,
        },
      };
    case photosActionTypes.GET_SEAT_MAP_REQUEST:
      return {
        ...state,
        seatMap: {
          ...state.seatMap,
          message: MESSAGES.SEAT_MAP_REQUESTED,
        },
      };
    case photosActionTypes.GET_SEAT_MAP_SUCCESSFUL:
      return {
        ...state,
        seatMap: {
          seatMapList: action.data.data,
          message: MESSAGES.SEAT_MAP_SUCCESSFUL,
        },
      };
    case photosActionTypes.GET_SEAT_MAP_FAILED:
      return {
        ...state,
        seatMap: {
          message: MESSAGES.SEAT_MAP_FAILED,
        },
      };
    case photosActionTypes.POST_MY_ASSET_PENDING:
      return Object.assign({}, state, {
        myAsset: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null,
        },
      });
    case photosActionTypes.POST_MY_ASSET_FULFILLED:
      return Object.assign({}, state, {
        myAsset: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload,
        },
      });
    case photosActionTypes.POST_MY_ASSET_REJECTED:
      return Object.assign({}, state, {
        myAsset: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload,
        },
      });
    case photosActionTypes.GET_ASSETS_SUMMARY_REQUEST:
      return {
        ...state,
        assetsSummary: {
          ...state.assetsSummary,
          message: MESSAGES.FETCHING_SUMMARY_REQUESTED,
        },
      };
    case photosActionTypes.GET_ASSETS_SUMMARY_SUCCESS:
      return {
        ...state,
        assetsSummary: {
          message: MESSAGES.FETCHING_SUMMARY_SUCCESSFUL,
          summary: action.summary,
        },
      };
    case photosActionTypes.GET_ASSETS_SUMMARY_FAILED:
      return {
        ...state,
        assetsSummary: {
          ...state.assetsSummary,
          message: MESSAGES.FETCHING_SUMMARY_FAILED,
        },
      };
    case photosActionTypes.GET_FAV_SUMMARY_REQUEST:
      return {
        ...state,
        favSummary: {
          ...state.favSummary,
          message: MESSAGES.FETCHING_SUMMARY_REQUESTED,
        },
      };
    case photosActionTypes.GET_FAV_SUMMARY_SUCCESS:
      return {
        ...state,
        favSummary: {
          message: MESSAGES.FETCHING_SUMMARY_SUCCESSFUL,
          summary: action.summary,
        },
      };
    case photosActionTypes.GET_FAV_SUMMARY_FAILED:
      return {
        ...state,
        favSummary: {
          ...state.favSummary,
          message: MESSAGES.FETCHING_SUMMARY_FAILED,
        },
      };
    case photosActionTypes.GET_OVERLAY_REQUEST:
      return {
        ...state,
        overlayAsset: {
          ...state.overlayAsset,
          overlays: dummyOverlays,
          message: MESSAGES.FETCHING_OVERLAY_REQUESTED,
        },
      };
    case photosActionTypes.GET_OVERLAY_SUCCESS:
      return {
        ...state,
        overlayAsset: {
          message: MESSAGES.FETCHING_OVERLAY_SUCCESSFUL,
          overlays: action.data.data.overlays,
        },
      };
    case photosActionTypes.GET_OVERLAY_FAILED:
      return {
        ...state,
        overlayAsset: {
          ...state.overlayAsset,
          message: MESSAGES.GET_OVERLAY_FAILED,
        },
      };
    default:
      return state;
  }
};
