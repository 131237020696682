import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, notification } from 'antd';
import { Link } from 'react-router-dom';
import { StadiumLogoContainer, StyledCheckbox } from './styles';

import stadiumImage from 'assets/images/stadium.jpg';

const FormItem = Form.Item;

const propTypes = {
  form: PropTypes.object.isRequired,
  signup: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const SignUp = ({ form, signup, history }) => {
  const onSuccess = () => {
    history.push('/signin');
  };

  const onError = error => {
    notification.error({
      message: error,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        signup({
          data: {
            username: values.email,
            password: values.password,
            attributes: {
              email: values.email,
            },
          },
          onSuccess,
          onError,
        });
      }
    });
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && !value.match('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$')) {
      callback(
        'Password should be of 8 characters and have atleast one uppercase letter and one lowercase letter and one digit',
      );
    }
    callback();
  };

  const { getFieldDecorator } = form;

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <StadiumLogoContainer>
                <img src={stadiumImage} alt="Neature" />
              </StadiumLogoContainer>
            </div>
            <div className="gx-app-logo-wid">
              <h1>Sign Up</h1>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={require('assets/images/logo.png')} />
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form className="gx-signup-form gx-form-row0">
              <FormItem>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    },
                  ],
                })(<Input placeholder="Email" />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      validator: validateToNextPassword,
                    },
                  ],
                })(<Input.Password placeholder="Password" />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator('confirm', {
                  rules: [
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    {
                      validator: compareToFirstPassword,
                    },
                  ],
                })(<Input.Password placeholder="Confirm Password" />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator('checkbox1', {
                  rules: [
                    {
                      required: true,
                      message: 'Please accept',
                    },
                  ],
                })(
                  <StyledCheckbox>
                    <span>
                      You agree with the{' '}
                      <a href="http://snaptivityapp.com/privacy.html" id="privacypolicy_link">
                        Privacy Policy
                      </a>{' '}
                      including collecting, storing and using your personal data for the service
                      delivery purposes.
                    </span>
                  </StyledCheckbox>,
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('checkbox2', {
                  rules: [
                    {
                      required: true,
                      message: 'Please accept',
                    },
                  ],
                })(
                  <StyledCheckbox id="privacypolicy_checkbox">
                    <span>
                      You agree to share your personal data with Ajax, Johan Cruijff Arena and their
                      sponsors
                    </span>
                  </StyledCheckbox>,
                )}
              </FormItem>
              <FormItem>
                <Button
                  type="primary"
                  className="gx-mb-0"
                  htmlType="submit"
                  id="signup_button"
                  onClick={handleSubmit}
                >
                  Sign Up
                </Button>
                <span>or</span> <Link to="/signin" id="signin_button">Sign In</Link>
              </FormItem>
              <div className="gx-flex-row gx-justify-content-between">
                {/* <span>or connect with</span> */}
                {/* <ul className="gx-social-link">
                    <li>
                      <Icon type="google" onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGoogleSignIn());
                      }}/>
                    </li>
                    <li>
                      <Icon type="facebook" onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userFacebookSignIn());
                      }}/>
                    </li>
                    <li>
                      <Icon type="github" onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGithubSignIn());
                      }}/>
                    </li>
                    <li>
                      <Icon type="twitter" onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userTwitterSignIn());
                      }}/>
                    </li>
                  </ul> */}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

SignUp.propTypes = propTypes;

const WrappedSignUpForm = Form.create()(SignUp);

export default WrappedSignUpForm;
