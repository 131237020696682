import campaignActionTypes from './constants/campaignActionTypes';

const initialState = {
  campaignFilter: {
    isFulfilled: false,
    data: null,
  },
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case campaignActionTypes.SET_CAMPAIGN_FILTER_FULFILLED:
      return {
        ...state,
        campaignFilter: {
          isFulfilled: true,

          data: action.data,
        },
      };

    case campaignActionTypes.REMOVE_CAMPAIGN_FILTER_FULFILLED:
      return {
        ...state,
        campaignFilter: {
          isFulfilled: true,
          data: null,
        },
      };
    default:
      return state;
  }
};

export default campaignReducer;
