import authActionTypes from './authActionTypes';

const initialState = {
  signinData: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null,
  },
  signupData: {
    isPending: false,
    isFulfilled: false,
    isRejected: false,
    data: null,
  },
  token: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.SIGNIN_PENDING:
      return Object.assign({}, state, {
        signinData: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null,
        },
        token: null,
      });
    case authActionTypes.SIGNIN_FULFILLED:
      return Object.assign({}, state, {
        signinData: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload.signInUserSession,
        },
        token: {
          access_token: action.payload.signInUserSession.accessToken.jwtToken,
          refresh_token: action.payload.signInUserSession.refreshToken.token,
        },
      });
    case authActionTypes.SIGNIN_REJECTED:
      return Object.assign({}, state, {
        signinData: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload,
        },
        token: null,
      });

    case authActionTypes.SIGNUP_PENDING:
      return Object.assign({}, state, {
        signupData: {
          isPending: true,
          isFulfilled: false,
          isRejected: false,
          data: null,
        },
      });
    case authActionTypes.SIGNUP_FULFILLED:
      return Object.assign({}, state, {
        signupData: {
          isPending: false,
          isFulfilled: true,
          isRejected: false,
          data: action.payload,
        },
      });
    case authActionTypes.SIGNUP_REJECTED:
      return Object.assign({}, state, {
        signupData: {
          isPending: false,
          isFulfilled: false,
          isRejected: true,
          data: action.payload,
        },
      });
    case authActionTypes.SIGNOUT:
      return Object.assign({}, state, {
        signinData: {
          isPending: false,
          isFulfilled: false,
          isRejected: false,
          data: null,
        },
        token: null,
      });
    case authActionTypes.UPDATE_TOKEN:
      return Object.assign({}, state, {
        token: {
          ...state.token,
          access_token: action.payload,
        },
      });
    default:
      return state;
  }
};

export default authReducer;
