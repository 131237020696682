import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import './../Thumbnails/styles.less';

const Starred = ({
  createFavoritePhotos,
  src,
  isFavourite,
  removeFavoritePhotos,
  topPicks,
  removeTopPicksFavoritePhotos,
  photoState,
  getFavoritePhotos,
  eventID,
  moments,
  seatID,
  campaignFilter,
}) => {
  const [starred, setstarred] = useState(isFavourite);
  const [starClicked, setStarClicked] = useState(false);
  const token = useSelector(({ authReducer }) => authReducer.token);

  const onClickStar = e => {
    e.stopPropagation();
    setStarClicked(prevState => !prevState);
  };

  const onClickFavoriteRemove = e => {
    e.stopPropagation();
    removeTopPicksFavoritePhotos({
      data: { asset_url: src, user_id: '', photoState },
    });

    getFavoritePhotos({
      data: {
        venueID: 'jca',
        getSummary: true,
        token: token,
        eventID: eventID.join(''),
        momentID: moments.join(''),
        seatID: seatID,
      },
    });
  };

  useEffect(() => {
    if (starClicked && !starred) {
      setstarred(prevState => !prevState);
      setStarClicked(prevState => !prevState);

      createFavoritePhotos({
        data: { asset_url: src, user_id: '' },
      });

      if (!campaignFilter) {
        getFavoritePhotos({
          data: {
            venueID: 'jca',
            getSummary: true,
            token: token,
            resetMoment: true,
          },
        });
      } else {
        getFavoritePhotos({
          data: {
            venueID: 'jca',
            getSummary: true,
            token: token,
            eventID: eventID.join(''),
            resetMoment: true,
          },
        });
      }
    }
    if (starClicked && starred) {
      setstarred(prevState => !prevState);
      setStarClicked(prevState => !prevState);

      removeFavoritePhotos({
        data: { asset_url: src, user_id: '' },
      });

      if (!campaignFilter) {
        getFavoritePhotos({
          data: {
            venueID: 'jca',
            getSummary: true,
            token: token,
            resetMoment: true,
          },
        });
      } else {
        getFavoritePhotos({
          data: {
            venueID: 'jca',
            getSummary: true,
            token: token,
            eventID: eventID.join(''),
            resetMoment: true,
          },
        });
      }
    }
  }, [
    starClicked,
    starred,
    createFavoritePhotos,
    removeFavoritePhotos,
    src,
    getFavoritePhotos,
    token,
    eventID,
    campaignFilter,
  ]);

  return (
    <>
      {!topPicks ? (
        <div onClick={onClickStar}>
          {starred ? (
            <i
              className="icon icon-star thumbnailStarIcon"
              style={{ color: '#ffffff' }}
              id="allPhotos_notfavorite_icon"
            />
          ) : (
            <i
              className="icon icon-star-o thumbnailStarIcon"
              style={{ color: '#ffffff' }}
              id="allPhotos_favorite_icon"
            />
          )}
        </div>
      ) : (
        <div onClick={onClickFavoriteRemove} style={{ cursor: 'pointer' }}>
          <i
            className="icon icon-star thumbnailStarIcon"
            style={{ color: '#ffffff' }}
            id="toppicks_favorite_icon"
          />
        </div>
      )}
    </>
  );
};

Starred.propTypes = {
  src: PropTypes.string,
  topPicks: PropTypes.bool,
  starred: PropTypes.bool,
  createFavoritePhotos: PropTypes.func,
  isFavourite: PropTypes.bool,
  removeFavoritePhotos: PropTypes.func,
  removeTopPicksFavoritePhotos: PropTypes.func,
  photoState: PropTypes.any,
};

export default Starred;
