import React from 'react';
import Modal from 'react-responsive-modal';

const modalStyle = {
  modal: {
    height: '875px',
    backgroundColor: '#f3f3f3',
    width: '900px',
    maxWidth: '1000px',
  },
  closeButton: {
    margin: '30px',
    cursor: 'pointer',
  },
  closeIcon: {
    fill: '#C8C8C8',
    width: '22px',
  },
  transitionExit: {
    opacity: '1',
  },
};

const BasicModal = props => {
  return (
    <Modal
      open={props.open}
      onClose={props.onCloseModal}
      styles={{ ...modalStyle, ...props.modalStyle }}
    >
      {props.children}
    </Modal>
  );
};
export default BasicModal;
